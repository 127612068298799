import { NavLink } from 'react-router-dom';
import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';
import './assets/style.css';
import heartImg from '../../assets/heart.png';
import sugarImg from '../../assets/sugar.png';
import armImg from '../../assets/arm.png';
import {useForm} from 'react-hook-form';

import { checkSymptoms, fetchAppointsbyid, fetchAllAppointsbyid, cancelAppbyid} from '../../api/common';

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation , useParams  } from 'react-router-dom';

import { FormInputComponent, LoadingAnimationComponent } from '../components';

const PatientAppointmentUpdate = () => {
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const [appoints, setAppoints] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const storedSessionDataString = localStorage.getItem('session');
  const storedSessionData = JSON.parse(storedSessionDataString);
  const currentUser = storedSessionData.id;
  console.log(currentUser);
    const fields =[
      [       
        // {fieldName: 'firstname', label: 'Doctor Name', placeholder: 'Dr. Jhon', validations: {
        //   required: "Doctor Name Required*"
        // }},
      //   {fieldName: 'DateTime', label: 'Appointment Date',  validations: {
      //  required: "Appointment Date Required*"
      // }, type: 'date'},

      {fieldName: 'description', label: 'Description',  type :'textarea', validations: {
        required: "Description Required*"
      }},

      {fieldName: 'StatusName', label: 'Appointment Status',  disabled : true, placeholder: 'In Progress',  validations: {
        
      }},

      ],
  
      
    ]

    
    const updatehandleClick = async() => {
      //navigate('/patient/phr/appointmentupdate', {replace: true});
      console.log('appid',appoints.AppointmentID);
      try {
          const  {status, message}  = await cancelAppbyid(appoints.AppointmentID);
          console.log(message);
          if(status >=400) throw new Error(message);
    else {
          console.log('cancel:',status);
//          window.location.reload(true)
      navigate('/patient', {replace: true});
    }
      } catch (error) {
          console.error('Error fetching Appoints:', error);
          // Handle error
      }
  };

  useEffect(() => {
    const fetchAppoints = async () => {
      try {
        const { status, data } = await fetchAppointsbyid(currentUser);
        console.log('data', data);
        // Handle fetched appointments
        if (status >= 400) {
          throw new Error(message);
        } else {
          // Filter appointments based on ID
          for (const appointment of data) {
            if (appointment.AppointmentID == id) {
                setAppoints(appointment);
                console.log('appointment found with ID:', appointment);
                break; // Once found, exit the loop
            } else {
              console.log('No appointment found with ID:', id);
            }
        }
        
        }
      } catch (error) {
        console.error('Error fetching Appointments:', error);
        // Handle error
      }
    };
  
    fetchAppoints();
  
  }, [id]);// Add dependencies for currentUser and id
  

  const handleAppUpdate =async values =>{
    setServerError(null);
    setSubmitting(true);
    const form =document.forms['phr-form'];
    try {
      [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
      values['userid'] = currentUser
      // const {status, message} =await createorupdatephr(values);

      if(status >=400) throw new Error(message);
      else {
        reset();
        navigate('/patient', {replace: true});
      }
    } catch (error) {
      if(error.response) setServerError(error.response.data.message);
      else setServerError(error.message);
    }
    setSubmitting(false);
    [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
  }

    return (
        <div className="section">
        <section className="hero">
      <div className="">
    
        <div className="logcolumn">
        <form className="phr-form"  onSubmit={handleSubmit(handleAppUpdate)}  style={{height: 'max-content'}} name='phr-form'>
          <h2>Update Appointment Information</h2><br />
          {serverError &&<div className="error-alert">{serverError}</div>}
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder,disabled}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} 
          disabled={disabled}   value={appoints[fieldName]}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Update</button></p>
          
            <br /><br />
 
       </>)}
     
         
          </div>
        </form>
        {/* <p className='newAcc'> Or</p> */}
        <p className='newAcc'> <button onClick={updatehandleClick}  className="health-btn" >Cancel Appointment</button></p>

        </div>
 
       
      </div>
    </section>

      
        <br/><br/><br/><br/><br/><br/>
      </div>
       

    );

}

export default PatientAppointmentUpdate;