import React, { useState, useEffect } from 'react';
import '../commonstyle.css';
import './assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import medicine1 from '../../assets/medicine1.png'
import medicine2 from '../../assets/medicine2.png'
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import {getPresByUserId, requestRefil } from '../../api/common';

function PatientPrescriptions() {

    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const storedSessionDataString = localStorage.getItem('session');
    const storedSessionData = JSON.parse(storedSessionDataString);
    const currentUser = storedSessionData.id;
    console.log(currentUser);
    const fields =[
      [       
        
        {fieldName: 'apd', label: 'Select Date',  validations: {
            required: "Date Required*"
          }, type: 'date'},
      ],
  
      
    ]

    const [presData, setPresData] = useState(null);
  useEffect(() => {
    
    const fetchUserData = async () => {
      try {
        console.log(currentUser);
          const data2 = await getPresByUserId(currentUser);
        console.log('data2',data2)
        setPresData(data2)
    
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  },[currentUser]);

  const handleButtonClick = async (prescriptionId) => {
    try {
        // Make an API call to update the prescription status
        const  {status, message} = await requestRefil(prescriptionId)

        // Handle fetched posts
        if(status >=400) throw new Error(message);
  else {
        console.log('map:',message);
        window.location.reload();
  }
        

        // Optionally, you can update the local state or perform any other action
    } catch (error) {
        console.error('Error updating prescription status:', error);
    }
};


  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">Prescriptions</div>
                
            <div className='row'>
                            <div className='col-sm-9'>
                                
                                <div className='view-threads'>
                                    <div className='thread'>
                                        <div className='row'>
                                          
                                            <div className='row'>
                                                <div className='thread-desc'>
                                             
                                                     
                                                      

                                                <table class="table">
    <thead>
        <tr>
            <th>Medicine Name</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>Instructions</th>
            <th>Status</th>
        </tr>
    </thead>
    <tbody id="prescriptions-table-body">
    {presData ? presData.map((prescription, index) => (
            <tr key={index}>
              <td>{prescription.MedicineName}</td>
              <td>{prescription.Dosage}</td>
              <td>{prescription.Frequency}</td>
              <td>{prescription.Instructions}</td>
              <td>{prescription.StatusName}</td>
              <td>
                {prescription.PrescriptionStatusID === 4 && (
                    <button className = "health-btn" onClick={() => handleButtonClick(prescription.PrescriptionID)}>Request Refil</button>
                )}
            </td>
            </tr>
          )) : <div></div>}
    </tbody>
</table>

                                                        


                                                </div>                                                
                                            </div>
                                            {/* <div className='row'>
                                                <div className='thread-by'>
                                                    <p>Prescribed by Dr.John on Date 2024-02-18 23:54</p>
                                                </div>                                                
                                            </div> */}

                                        </div>
                                    
                                    </div>
                                
                                    {/* <p className="communityline" > </p>
                                    <br/>
                                    <div className='thread'>
                                   <center> <h4>Previous Prescriptions</h4></center>
                                        <div className='row'>
                                            <div className='row'>
                                                <div className='col-sm thread-head'>
                                                    <h4>Prescription 1</h4>
                                                </div>
                                                <div className='col-sm thread-count'>
                                                    <p> <button className='health-btn'>Request Refill</button></p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='thread-desc'>
                                                                                                 
                                                      

    <table class="table">
      <thead>
        <tr>
          <th>Medicine Name</th>
          <th>Dosage</th>
          <th>Frequency</th>
          <th>Instructions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Vitamins</td>
          <td>500mg</td>
          <td>Take 1 tablet for every 4 hours</td>
          <td>Take with food to avoid stomach upset.</td>
        </tr>
        <tr>
          <td>Calcium</td>
          <td>500mg</td>
          <td>Take 1 tablet for every 4 hours</td>
          <td>Do not exceed 4,000 mg in 24 hours.</td>
        </tr>
        <tr>
          <td>Insulin</td>
          <td>500mg</td>
          <td>Take 1 tablet for morning and night</td>
          <td>If symptoms persist, consult a healthcare professional.</td>
        </tr>

      </tbody>
    </table>
 
                                                </div>                                                
                                            </div>
                                            <div className='row'>
                                                <div className='thread-by'>
                                                  
                                                </div>                                                
                                            </div>

                                        </div>
                                    
                                    </div>
                                    <p className="communityline" > </p>
                                    <br/>
                                    <div className='thread'>
                                            <div className='row'>
                                            <div className='row'>
                                                <div className='col-sm thread-head'>
                                                    <h4>Prescription 2</h4>
                                                </div>
                                                <div className='col-sm thread-count'>
                                                    <p> <button className='health-btn'>Request Refill</button></p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='thread-desc'>
                                                                                                 
                                                      


                                                </div>                                                
                                            </div>
                                            <div className='row'>
                                                <div className='thread-by'>
                                                  
                                                </div>                                                
                                            </div>

                                        </div>
                                    
                                    </div> */}
                                </div>
                          
                              
                            </div>
                            <div className='col-sm-3'>

                            <div className='healthforum-add'>
                                  
                                  <div className='row'>
                                      <div className='add-healthforum'>
                                        <div className="health-form">
                                        <h2>Medication Alerts</h2><br />
                                        <h6 className='medicine-alert-desc'>Its time to take below medicine</h6><br />
                                        <div className='capsule'>
             <div className='row'>
              <div className='col-sm-4'>
                  <div className='cap-image'>
                      
               <img className="capimg" alt="Group" src={medicine2} />
                            
                  </div>
              </div>
              <div className='col-sm-8'>
                  <div className='row'>
                      <h6>Vitamin Tablets</h6>
                  </div>
                  
                  <div className='row'>
                  <p>Take 1 tablet for every 4 hours </p>
                  </div>

              </div>
           
          </div>
      </div>
                                        </div>
                                     
                                      </div>
                                  </div>

                              </div>

{/* 
                                <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                        <form className="health-form"  onSubmit={handleSubmit(handleSignup)}  style={{height: 'max-content'}} name='login-form'>
          <h2>Your Medications</h2><br />
         
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}

          </div>)} 
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Get Medicine Schedule</button></p>
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
       
        <div className='row'>
    <div className='medications'>
        <h2>Result </h2>
     
            <div className='capsule'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='cap-image'>
                        
                 <img className="capimg" alt="Group" src={medicine2} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                    <div className='row'>
                        <h6>Vitamin Tablets</h6>
                    </div>
                    
                    <div className='row'>
                    <p>Take 1 tablet for every 4 hours</p>
                    </div>

                </div>
             
            </div>
        </div>

        <div className='capsule'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='cap-image'>
                        
                 <img className="capimg" alt="Group" src={medicine1} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                    <div className='row'>
                        <h6>Calcium Tablets</h6>
                    </div>
                    
                    <div className='row'>
                    <p>Take 1 tablet for every 4 hours</p>
                    </div>

                </div>
             
            </div>
        </div>

    </div>

</div>
        </form>

                                        </div>
                                    </div>

                                </div> */}


                        </div>
               
                   

                </div>
                </div>
        
                

            </div>
        </div>


    </div>

);

}
export default PatientPrescriptions;
