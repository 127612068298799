import React, { useState } from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import '../healthcareprovider/assets/style.css';
import calendarImg from '../../assets/calendarillustration.png'
import surgeryImg from '../../assets/surgeryillustration.png'
import callImg from '../../assets/callillustration.png'
import doctorImg from '../../assets/doctor.webp'
import userImg from '../../assets/user.webp'
import dataImg from '../../assets/data1.jpg'
import patient1 from '../../assets/patient1.jpg'
import patient2 from '../../assets/patient2.jpg'
import {useForm} from 'react-hook-form';
import {NavLink, useNavigate} from 'react-router-dom';
import { FormInputComponent, LoadingAnimationComponent } from '../components';

function AdminDataOversight() {

    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const fields =[
      [       
        
        {fieldName: 'countries', label: 'Country', type: 'select', validations: {
          required: "Country Required*"
        }, options: [
          {value: '', label: 'Select', disabled: true, selected: true},
          {value: 'US', label: 'US'},
          {value: 'UK', label: 'UK'},
          {value: 'Canada', label: 'Canada'},
          {value: 'Mexico', label: 'Mexico'},      
        ]},
        // {fieldName: 'role', label: 'Role', type: 'select', validations: {
        //   required: "Specify your role*"
        // }, options: [
        //   {value: '', label: 'Select', disabled: true, selected: true},
        //   {value: '1', label: 'Patients'},
        //   {value: '2', label: 'Healthcare Providers'},
        //   {value: '3', label: 'Administrator'},
        //   {value: '4', label: 'Health Administrator'},
        //   {value: '5', label: 'Pharmacist'},
        // ]}
      ],
  
      
    ]

  

  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">Data by Country/User</div>
                
            <div className='row'>
                            <div className='col-sm-8'>
                                <div className='healthproviderdash-left'>
                                  <div>
                                  <img className="globe-image" alt="Group" src={dataImg} />
                                  </div>
                                    <div className='row'>
                                    <div className="community-head">Result</div>
                                        <div className='col-sm'>

            <div className='capsule1'>
               <div className='row'>
              
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>Security</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>Status : Good</h6>

                  </div>

                </div>
             
            </div>
            </div>
              

                                        </div>
                                        <div className='col-sm'>
                                        <div className='capsule1'>
               <div className='row'>
                
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>System Status</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>Status : Live</h6>

                  </div>

                </div>
             
            </div>
            </div>
                                            </div>
                                            <div className='col-sm'>
                                            <div className='capsule1'>
               <div className='row'>
             
                <div className='col-sm-8'>
                <div className='row '>
                      <h5 className='newAcc'>Network Traffic</h5>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>Status : Moderate</h6>

                  </div>

                </div>
             
            </div>
            </div>  
                                            </div>

                                    </div>
                                  
                                </div>
                                
                            
                              
                            </div>
                            <div className='col-sm-4'>

                           


                                <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                        <form className="health-form"  style={{height: 'max-content'}} name='login-form'>
          <h2>Get Overview</h2><br />
         
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}

          </div>)} 
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Search</button></p>
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
    
        </form>

                                        </div>
                                    </div>

                                </div>


                        </div>
               
                   

                </div>

                
                </div>
        
                
                <br /><br /><br /><br /><br />
            </div>
        </div>

     
    </div>

);

}
export default AdminDataOversight;
