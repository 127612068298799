import { useEffect } from 'react';
import {routes} from '../../utils/routes';
import {Outlet, useNavigate} from 'react-router-dom';
import {FooterComponent,NavigationBarComponent} from '../components';

const HealthProviderLayoutPage = () => {
  const navigate = useNavigate();
  const links =[
    {to: '/healthprovider', label: 'Home'},
    {to: '/healthprovider/patients', label: 'Patients'},
    {to: '/healthprovider/community', label: 'Health Forums'},
    {to: '/healthprovider/chat', label: 'Chat'},
    {to: '/logout', label: 'Logout'},
  ]

  useEffect(() =>{
    const {isLoggedIn, role} =JSON.parse(localStorage.getItem('session')) || {};
    if(!isLoggedIn) navigate('/login', {replace: true});
    if(role !='2') navigate(routes[role]|| '/logout', {replace: true});
  },[]);
  return (
    <>
    <NavigationBarComponent links={links} heading={''}/>
    <Outlet />
    <FooterComponent />
    </>
  )
}

export default HealthProviderLayoutPage;