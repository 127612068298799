const FormInputComponent = ({name, type ='text', label, error ={}, register ={}, options =[], placeholder ='' , value, disabled}) => {
  return (
    <div className='form-input'>
        {label && <label htmlFor={name}>{label}</label>}
        {type === "textarea" ? 
          <textarea rows={3} className={`${error[name] && 'has-error'}`} placeholder={placeholder} {...register} value={value}  disabled={disabled}></textarea> : 
          type === "select" ? 
          <select defaultValue={value} {...register} className={`${error[name] && 'has-error'}`} >
            {options.map(({value, label, disabled}, index) => (
              <option key={index} value={value} disabled={disabled}>{label}</option>
            ))}
          </select> : 
          <input type={type} className={`${error[name] && 'has-error'}`} placeholder={placeholder} {...register} value={value} disabled={disabled}/>
        }
        {error[name] && <small className='error'>{error[name].message || error[name]}</small>}
    </div>
  );
}

export default FormInputComponent;
