import { api } from ".";


export const getallmessagesbycurrentuser =async currentuser =>{
   
    try {
        const encodedId = encodeURIComponent(currentuser);
        const response = await api.get(`/getallmessagesbycurrentuser?id=${encodedId}`);
        return response;  
    } catch (error) {
        console.error('Error fetching user data:', error.message)
        return null
        // Handle error
       // throw error;
    }

}



export const sendmess =async details =>{
 
   try {
    const response =await api.post('/sendmessage', details);  
    return response;  
} catch (error) {
    console.error('Error fetching user data:', error.message)
    return null
    // Handle error
   // throw error;
}


}