import {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from './components';
import {routes} from '../utils/routes'
import './StaticHome/css/style.css';
import logImage from '../assets/loginpic.jpg';

import { login_user } from '../api/auth';

const LoginPage = () => {
  const {register, handleSubmit, reset, formState: {errors}} =useForm();
  const [serverError, setServerError] =useState(null);
  const [submitting, setSubmitting] =useState(false);
  const navigate =useNavigate();

  const fields =[
    {fieldName: 'email', label: 'Email', validations: {
      required: '*Email ID required',
    }},
    {fieldName: 'password', label: 'Password', type: "password", validations: {
      required: '*Password required',
    }},
  ]
  const handleLogin =async values =>{
    setServerError(null);
    setSubmitting(true);
    const form =document.forms['login-form'];
    console.log(values);
    try {
      [...form.querySelectorAll('input, button')].forEach(field => field.disabled =true);
      const {status, message, role, id, username} = await login_user(values);
      if(status >=400) throw new Error(message);
      else {
        localStorage.setItem('session', JSON.stringify({isLoggedIn: true, id, role, username}));
        reset();
        navigate(routes[role], {replace: true});
      }
    } catch (error) {
      console.log(error.response);
      if(error.response) setServerError(error.response.data.message);
      else setServerError(error.message);
    }
    setSubmitting(false);
    [...form.querySelectorAll('input, button')].forEach(field => field.disabled =false);
  }
  return (
      <div >
        <section >
      <div className="container common-container">
        <div className='row'>
        <div className="col-sm">
        <img
            src={logImage}
            alt="illustration"
            className="loghero-image"
          />
        </div>
        <div className="col-sm">
        <form className="login-form" onSubmit={handleSubmit(handleLogin)} name='login-form'>
          <h2>Login</h2>
          {serverError &&<div className="error-alert">{serverError}</div>}
          {fields.map(({fieldName, type, label, validations}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} register={register(fieldName, validations)} error={errors}/>))}
          
          <div className="options">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Login</button></p>
            <br />
           {/* <p className='newAcc'> <NavLink to="/forgot-password">Forgot Password?</NavLink></p> */}
           <p className='newAcc'>Or</p>
            <p className='newAcc'>Don't have account? <NavLink to="/signup" > &nbsp;Create new account</NavLink></p></>)}
          
          </div>
        </form>
        </div>
        </div>
      </div>
    </section>

  
      </div>
  )
}

export default LoginPage;