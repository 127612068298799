import React, { useState, useEffect } from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import { createincident, getAllIncidents } from '../../api/common';

function HealthAdministratorIncidents() {
    const storedSessionDataString = localStorage.getItem('session');
    const storedSessionData = JSON.parse(storedSessionDataString);
    const currentUser = storedSessionData.id;
    console.log(currentUser);
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const fields =[
      [       
        
      
      {fieldName: 'incdesc', label: 'Incident Description', type :'textarea', validations: {
        required: "Description Required*"
      }},

      ],
  
      
    ]



    const [incData, setIncData] = useState(null);
    useEffect(() => {
      
      const getincidents = async () => {
        try {
            const data2 = await getAllIncidents();
          console.log('data2',data2)         
           setIncData(data2.data)
      
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
  
      if(!incData){
      getincidents();
      }
    });

    const handleIncident =async values =>{
        setServerError(null);
        setSubmitting(true);
        const form =document.forms['Inc-form'];
        try {
          [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
          values['healthid'] = currentUser
          console.log(values)
          const {status, message} =await createincident(values);
        
          if(status >=400) throw new Error(message);
          else {
            reset();
            window.location.reload();            //navigate('/login', {replace: true});
          }
        } catch (error) {
          if(error.response) setServerError(error.response.data.message);
          else setServerError(error.message);
        }
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
      }

  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">Latest Incidents</div>
                
            <div className='row'>
                            <div className='col-sm-8'>
                                
                                <div className='view-threads'>
                                <div>
  {incData ? incData.map((incident, index) => (
    <div key={index} className='thread'>
      <div className='row'>
        <div className='row'>
          <div className='col-sm thread-head'>
            <h4>Incident Id : {incident.IncidentResponseID}</h4>
          </div>
          <div className='col-sm thread-count'>
            <p>Status : {incident.IncidentResponseStatus}</p>
          </div>
        </div>
        <div className='row'>
          <div className='thread-desc'>
            <p>{incident.IncidentResponseDescription}</p>
          </div>                                                
        </div>
        <div className='row'>
          <div className='thread-by'>
            <p>Created by {incident.HealthAdministratorID} </p>
          </div>                                                
        </div>
      </div>
    </div>
  )) : <div></div>}
</div>

                                    <p className="communityline" > </p>
                                    <br/>
                              
                                </div>
                          
                              
                            </div>
                            <div className='col-sm-4'>

                                <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                        <form className="health-form"  onSubmit={handleSubmit(handleIncident)}  style={{height: 'max-content'}} name='Inc-form'>
          <h2>Create New Incident</h2><br />

          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Create</button></p>
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>

                                        </div>
                                    </div>

                                </div>

                        </div>
               
                   

                </div>
                </div>
        
                

            </div>
        </div>


    </div>

);

}
export default HealthAdministratorIncidents;
