import React, { useState } from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import { facilitybycountry } from '../../api/common';


function HealthAdministratorFacilities() {

    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const fields =[
      [       
        
      {fieldName: 'country', label: 'Location', type: 'select', validations: {
        required: "Specify your Location*"
      }, options: [
        {value: '', label: 'Select', disabled: true, selected: true},
        {value: 'US', label: 'US'},
        {value: 'UK', label: 'UK'},
        {value: 'Canada', label: 'Canada'},
      ]},
     

      ],
  
      
    ]

    const [facData, setFacData] = useState(null);

    const handleFacility=async values =>{
        setServerError(null);
        setSubmitting(true);
        const form =document.forms['facility-form'];
        try {
          [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
       
          const {status, message, data } =await facilitybycountry(values['country']);
    
          if(status >=400) throw new Error(message);
          else {
            console.log(data)
            setFacData(data)
            //reset();
            //navigate('/login', {replace: true});
          }
        } catch (error) {
          if(error.response) setServerError(error.response.data.message);
          else setServerError(error.message);
        }
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
      }

  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">Facilities Result</div>
                
            <div className='row'>
                            <div className='col-sm-8'>
                                
                                <div className='view-threads'>
                                    <div className='thread'>
                                        <div className='row'>
                                        <table class="table table-striped">
    <thead>
      <tr>
        <th>Facility Name</th>
        <th>City</th>
        <th>Country</th>
        <th>Offered Services</th>
        <th>Operational Status</th>
        <th>Facility Contact Number</th>
      </tr>
    </thead>
    <tbody id="prescriptions-table-body">
    {facData ? facData.map((fac, index) => (
            <tr key={index}>
              <td>{fac.FacilityName}</td>
              <td>{fac.City}</td>
              <td>{fac.Country}</td>
              <td>{fac.OfferedServices}</td>
              <td>{fac.OperationalStatus}</td>
              <td>{fac.FacilityContactNumber}</td>
            </tr>
          )) : <div></div>}
    </tbody>
  </table>
                                        </div>
                                     
                                    
                                    </div>
                                
                                   
                                </div>
                          
                              
                            </div>
                            <div className='col-sm-4'>

                                <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                        <form className="health-form"  onSubmit={handleSubmit(handleFacility)}  style={{height: 'max-content'}} name='facility-form'>
          <h2>Facilities By Location</h2>
          <div className='row'>
                                    </div>
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Check Facilities</button></p>
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>

                                        </div>
                                    </div>

                                </div>

                        </div>
               
                   

                </div>
                </div>
        
                
<br/><br/><br/><br/><br/><br/>
            </div>
        </div>


    </div>

);

}
export default HealthAdministratorFacilities;
