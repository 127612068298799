import {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from './components';
import forgotImage from '../assets/forgot.webp';

const ForgotPassword = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
  const [serverError, setServerError] =useState(null);
  const [submitting, setSubmitting] =useState(false);
  const navigate =useNavigate();
  const fields =[
    {fieldName: 'email', label: 'Email', type: 'email', validations: {
      required: 'Email required*',
      isEmail: 'Provide a valid email address'
    }},
    {fieldName: 'cemail', label: 'Confirm Email', type: 'password', validations: {
      required: 'Confirm your email',
      validate: v => v !=watch('email')? "Emails do not match": null,
    }},
    
  ]

  

  const handleForgotPassword =async ({email}) =>{
    setServerError(null);
    setSubmitting(true);
    const form =document.forms['reset-form'];
    try {
      [...form.querySelectorAll('input, button')].forEach(field => field.disabled =true);
      const {status, message, id} =await confirm_email({email});
      if(status >=400) throw new Error(message);
      else {
        reset();
        console.log(email,id)
       const {status, message} =await send_forgot_email({email,id});
      
       if(status >= 400) throw new Error(message);
       else{
        setSuccessMessage('An email with a password reset link has been sent to your email address.');
       }
       // navigate(`/reset-password/${id}`, {replace: true});
      }
    } catch (error) {
      if(error.response) setServerError(error.response.data.message);
      else setServerError(error.message);
    }
    setSubmitting(false);
    [...form.querySelectorAll('input, button')].forEach(field => field.disabled =false);
  }

   return (
    <div className="containerfp">
        <form className="forgot-form" onSubmit={handleSubmit(handleForgotPassword)} style={{height: 'max-content'}} name='reset-form'>
          <h2>Forgot Password</h2>
          {successMessage && <div className="success-alert">{successMessage}</div>}
          <img
          src={forgotImage}
          alt="illustration"
          className="forgothero-image"
        />
          {fields.map(({fieldName, type, label, validations}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} register={register(fieldName, validations)} error={errors}/>))}
          <br />
          {submitting? <LoadingAnimationComponent />: (<>
            <p className='newAcc'>   <button className="health-btn" type="submit">Reset Password</button></p><br />
            <br />
            <p className='newAcc'>    <NavLink to="/login">Back to Login</NavLink></p></>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
       
          
        </form>
        
      </div>

  )
}

export default ForgotPassword;