import { useEffect } from 'react';
import {routes} from '../../utils/routes';
import {Outlet, useNavigate} from 'react-router-dom';
import {FooterComponent, NavigationBarComponent} from '../components';

const PharmacistLayoutPage = () => {
  const navigate = useNavigate();
  const links =[
    {to: '/pharmacist', label: 'Home'},
    {to: '/pharmacist/medicaldispense', label: 'Medical Dispenstion'},
    {to: '/pharmacist/chat', label: 'Chat'},

    
    {to: '/logout', label: 'Logout'},
  ]

  useEffect(() =>{
    const {isLoggedIn, role} =JSON.parse(localStorage.getItem('session')) || {};
    if(!isLoggedIn) navigate('/login', {replace: true});
    if(role !='5') navigate(routes[role]|| '/logout', {replace: true});
  },[]);
  return (
    <>
    <NavigationBarComponent links={links} heading={''}/>
    <Outlet />
    <FooterComponent />
    </>
  )
}

export default PharmacistLayoutPage;