import {NavLink, useNavigate} from 'react-router-dom';
import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';
import '../patient/assets/style.css';
import heartImg from '../../assets/heart.png';
import sugarImg from '../../assets/sugar.png';
import armImg from '../../assets/arm.png';
import React, { useState } from 'react';
import {useForm} from 'react-hook-form';
import { updateAppoint, cancelAppbyid} from '../../api/common';
import { useParams } from 'react-router-dom';
import { FormInputComponent, LoadingAnimationComponent } from '../components';

const HealthProviderAppointmentUpdate = () => {
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const storedSessionDataString = localStorage.getItem('session');
    const storedSessionData = JSON.parse(storedSessionDataString);
    const currentUser = storedSessionData.id;
    console.log(currentUser);
    const { id } = useParams();
    const navigate = useNavigate();
    const fields =[
      [       
      //   {fieldName: 'firstname', label: 'Patient Name', placeholder: 'Jospeh', validations: {
      //       required: "Patient Name Required*"
      //   }},
      //   {fieldName: 'apd', label: 'Appointment Date',  validations: {
      //   required: "Appointment Date Required*"
      // }, type: 'date'},

      // {fieldName: 'heartrate', label: 'Description', type :'textarea', validations: {
      //   required: "Description Required*"
      // }},

    
  
      {fieldName: 'MedicineName', label: 'Medicine Name',  validations: {
        required: "Medicine Name Required*"
      }},

      
      {fieldName: 'Dosage', label: 'Dosage',  validations: {
        required: "Dosage Required*"
      }},
      {fieldName: 'Frequency', label: 'Frequency',  validations: {
        required: "Frequency Required*"
      }},

      {fieldName: 'Instructions', label: 'Instructions', type :'textarea', validations: {
        required: "Instructions Required*"
      }},
      {fieldName: 'appstatus', label: 'Appointment Status', type: 'select', validations: {
        required: "Specify your Category*"
      }, options: [
        {value: '1', label: 'In Progress', selected: true},
        {value: '2', label: 'Completed'},
      ]},

      ],
  
      
    ]
     
    const UpdateAppoint =async values =>{
      setServerError(null);
      setSubmitting(true);
      const form =document.forms['App-form'];
      try {
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
          console.log(values);
          values['userid'] = currentUser
          values['appointmentid'] = id
        const {status, message} =await updateAppoint(values);
  
        if(status >=400) throw new Error(message);
        else {
          //reset();
          navigate('/healthprovider', {replace: true});

        }
      } catch (error) {
        if(error.response) setServerError(error.response.data.message);
        else setServerError(error.message);
      }
      setSubmitting(false);
      [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
    }

    const updatehandleClick = async() => {
      //navigate('/patient/phr/appointmentupdate', {replace: true});
      console.log('appid',id);
      try {
          const  {status, message}  = await cancelAppbyid(id);
          console.log(message);
          if(status >=400) throw new Error(message);
    else {
          console.log('cancel:',status);
//          window.location.reload(true)
      navigate('/patient', {replace: true});
    }
      } catch (error) {
          console.error('Error fetching Appoints:', error);
          // Handle error
      }
  };

    return (
        <div className="section">
        <section className="hero">
      <div className="">
    
        <div className="logcolumn">
        <form className="App-form"  onSubmit={handleSubmit(UpdateAppoint)}  style={{height: 'max-content'}} name='App-form'>
          <h2>Update Appointment Information</h2><br />
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Update</button></p>

            <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>
        <p className='newAcc'> <button className="health-btn" onClick={updatehandleClick}>Cancel Appointment</button></p>

        </div>
 
       
      </div>
    </section>

      
        <br/><br/><br/><br/><br/><br/>
      </div>
       

    );

}

export default HealthProviderAppointmentUpdate;