import {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import '../StaticHome/css/style.css';
import logImage from '../../assets/signpic.png';
import { useParams } from 'react-router-dom';

import {presDelivered} from '../../api/common';



const PharmacistMedicalDispensationUpdate = () => {
  const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
  const [serverError, setServerError] =useState(null);
  const [submitting, setSubmitting] =useState(false);
  const navigate =useNavigate();
  const { id } = useParams();
  const fields =[
    // [{fieldName: 'firstname', label: 'Dispense Id', validations: {
    //   required: "Dispense Id Required*"
    // }},
    // {fieldName: 'lastname', label: 'Instructions', type:'textarea',validations: {
    //   required: "Instructions Required*"
    // }}],

    
    [
      {fieldName: 'status', label: 'Status', type: 'select', validations: {
        required: "Status Required*"
      }, options: [
        {value: '4', label: 'Delivered'},

      ]},
      ],
  ]

  const handleButtonClick = async () => {
    try {
        // Make an API call to update the prescription status
        const  {status, message} = await presDelivered(id)

        // Handle fetched posts
        if(status >=400) throw new Error(message);
  else {
        console.log('map:',message);
        navigate('/pharmacist/medicaldispense', { replace: true });
  }
        

        // Optionally, you can update the local state or perform any other action
    } catch (error) {
        console.error('Error updating prescription status:', error);
    }
};


  return (
      <div className="">
        <section className="">
      <div className="container common-container">
    <div className='row'>
        <div className="col-sm">
          <div className='loginform-block'>
        <form className="login-form" onSubmit={handleSubmit(handleButtonClick)} style={{height: 'max-content'}} name='login-form'>
          <h2>Update Medication Dispense Information</h2><br />
          {fields.map((row, index) =><div key={index} className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Update</button></p>
          
 </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>
        </div>
        </div>
 
     
      </div>
      </div>
    </section>

      
        <br/><br/><br/><br/><br/><br/>
      </div>
  )
}

export default PharmacistMedicalDispensationUpdate;