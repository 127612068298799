import React, { useState } from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';

function HealthAdministratorComplaince() {

    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const fields =[
      [       
        
      {fieldName: 'role', label: 'Location', type: 'select', validations: {
        required: "Specify your Location*"
      }, options: [
        {value: '', label: 'Select', disabled: true, selected: true},
        {value: '1', label: 'US'},
        {value: '2', label: 'UK'},
        {value: '3', label: 'Canada'},
      ]},
     

      ],
  
      
    ]

    const handleSignup =async values =>{
        setServerError(null);
        setSubmitting(true);
        const form =document.forms['signup-form'];
        try {
          [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
          delete values.cpassword;
          const {status, message} =await create_user(values);
    
          if(status >=400) throw new Error(message);
          else {
            reset();
            navigate('/login', {replace: true});
          }
        } catch (error) {
          if(error.response) setServerError(error.response.data.message);
          else setServerError(error.message);
        }
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
      }

  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">Complaince Status</div>
                
            <div className='row'>
                            <div className='col-sm-12'>
                                
                                <div className='view-threads'>
                                    <div className='thread'>
                                        <div className='row'>
                                        <table class="table table-bordered">
    <thead>
      <tr>
      <th>Name</th>
      <th>Location</th>
        <th>Aspect</th>
        <th>Description</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
      <td>ABC Hospital</td>
      <td>US</td>
        <td>Regulatory Requirements</td>
        <td>
          <ul>
            <li>HIPAA (Health Insurance Portability and Accountability Act)</li>
            <li>GDPR (General Data Protection Regulation)</li>
            <li>FDA Regulations for Medical Devices</li>
          </ul>
        </td>
        <td>Following</td>
      </tr>
      <tr><td>ABC Hospital</td>
      <td>US</td>
        <td>Compliance Framework</td>
        <td>Develop a comprehensive compliance framework outlining policies, procedures, and controls for achieving and maintaining compliance with healthcare regulations.</td>
        <td>Following</td> </tr>
      <tr><td>ABC Hospital</td>
      <td>US</td>
        <td>Compliance Monitoring Tools</td>
        <td>Implement monitoring tools and mechanisms for continuous assessment of platform adherence to regulatory requirements and standards.</td>
        <td>Following</td></tr>
      <tr><td>ABC Hospital</td>
      <td>US</td>
        <td>Data Security and Privacy Measures</td>
        <td>Implement robust data security and privacy measures, including encryption, access controls, and auditing capabilities, to protect sensitive healthcare information.</td>
        <td>Following</td></tr>
      <tr><td>ABC Hospital</td>
      <td>US</td>
        <td>User Access Controls</td>
        <td>Implement user access controls to restrict access to sensitive data and functionalities based on roles and permissions.</td>
        <td>Following</td> </tr>
      <tr><td>ABC Hospital</td>
      <td>US</td>
        <td>Audit Trails and Logging</td>
        <td>Maintain comprehensive audit trails and logging mechanisms to track user activities, data access, and system changes.</td>
        <td>Following</td> </tr>
      <tr><td>ABC Hospital</td>
      <td>US</td>
        <td>Compliance Training and Awareness</td>
        <td>Provide training and awareness programs for employees and stakeholders to educate them about healthcare regulations and compliance requirements.</td>
        <td>Following</td> </tr>
      <tr><td>ABC Hospital</td>
      <td>US</td>
        <td>Incident Response and Reporting</td>
        <td>Establish procedures for incident response and reporting in case of security breaches or compliance violations.</td>
        <td>Following</td></tr>
      <tr><td>ABC Hospital</td>
      <td>US</td>
        <td>Continuous Improvement</td>
        <td>Regularly review and update your compliance program to adapt to changes in regulations and industry standards.</td>
        <td>Following</td> </tr>
    </tbody>
  </table>
                                        </div>
                                     
                                    
                                    </div>
                                
                                   
                                </div>
                          
                              
                            </div>
                            {/* <div className='col-sm-4'>

                                <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                        <form className="health-form"  onSubmit={handleSubmit(handleSignup)}  style={{height: 'max-content'}} name='login-form'>
          <h2>Select Location to check standards</h2>
          <div className='row'>
                                    </div>
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Check Standards/Adherence</button></p>
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>

                                        </div>
                                    </div>

                                </div>

                        </div>
                */}
                   

                </div>
                </div>
        
                
<br/><br/><br/><br/><br/><br/>
            </div>
        </div>


    </div>

);

}
export default HealthAdministratorComplaince;
