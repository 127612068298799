import { api } from ".";



export const create_user =async user_data =>{
    const response = await api.post('/createuser', user_data);
    const {status, data: {message}} =response;
    return {status, message}
}

export const updateuser =async user_data =>{
    const response = await api.post('/updateuser', user_data);
    const {status, data: {message}} =response;
    return {status, message}
}



export const fetch_userbyid =async (id) =>{    
    try {
        // Encode the user ID to handle special characters properly
        const encodedId = encodeURIComponent(id);
        const response = await api.get(`/userbyid?id=${encodedId}`);
        const data = response.data
        return data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error
       return null
    }
}

export const fetch_userbyname = async (name) => {
    try {
        // Encode the name to handle special characters properly
        const encodedName = encodeURIComponent(name);
        const response = await api.get(`/userbyname?name=${encodedName}`);
        const data = response.data;
        console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching user data by name:', error);
        // Handle error
        throw error;
    }
}


export const fetch_patbyid =async (id) =>{    
    try {
        // Encode the user ID to handle special characters properly
        const encodedId = encodeURIComponent(id);
        const response = await api.get(`/patientbyid?id=${encodedId}`);
        const data = response.data
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error
        throw error;
    }
}

export const fetch_patbyname = async (name) => {
    try {
        // Encode the name to handle special characters properly
        const encodedName = encodeURIComponent(name);
        const response = await api.get(`/patientbyname?name=${encodedName}`);
        const data = response.data;
        console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching user data by name:', error);
        // Handle error
        throw error;
    }
}


export const gethealthproviderappointments =async (id) =>{    
    try {
        // Encode the user ID to handle special characters properly
        const encodedId = encodeURIComponent(id);
        const response = await api.get(`/healthproviderapps?id=${encodedId}`);
        const data = response.data
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error
        throw error;
    }
}

export const getappcounts=async() =>{    
    try {
        // Encode the user ID to handle special characters properly
        const response = await api.get(`/appointmentscount`);
        const data = response.data
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error
        throw error;
    }
}

export const getuserscounts=async() =>{    
    try {
        // Encode the user ID to handle special characters properly
        const response = await api.get(`/userscount`);
        console.log(response)

        const data = response.data
        return data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error
        throw error;
    }
}

export const getfacilitiescounts=async() =>{    
    try {
        // Encode the user ID to handle special characters properly
        const response = await api.get(`/facilitiescount`);
        console.log(response)

        const data = response.data
        return data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error
        throw error;
    }
}
export const getincidentcounts=async() =>{    
    try {
        // Encode the user ID to handle special characters properly
        const response = await api.get(`/incidentscount`);
        console.log(response)

        const data = response.data
        return data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error
        throw error;
    }
}




export const getallprescriptions=async() =>{    
    try {
        // Encode the user ID to handle special characters properly
        const response = await api.get(`/getAllPrescriptions`);
        console.log(response)

        const data = response.data
        return data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error
        throw error;
    }
}
