import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import {HomePage,LayoutPage } from './pages/StaticHome';
import {LoginPage, SignUpPage,ForgotPassword, ServicesPage, About, Contact, Blog, LogoutPage } from './pages';
import {PatientLayoutPage, PatientDashboard, PatientHealth, PatientCommunity, PatientHealthUpdate, PatientAppointmentUpdate, PatientAppointmentAdd, PatientPrescriptions } from './pages/patient';
import { HealthProviderCommunity, HealthProviderDashboard, HealthProviderLayoutPage, HealthProviderAppointmentView, HealthProviderAppointmentUpdate, HealthProviderPatients, HealthProviderPatientInfo } from './pages/healthcareprovider';
import AdminLayoutPage from './pages/administrator/AdminLayoutPage';
import { AdminDashboard, AdminDataOversight, AdminHealthProviderInfo, AdminHealthProviders, AdminSystemConfiguration, AdminUserAddNew, AdminUserInfo, AdminUserUpdate, AdminUsers } from './pages/administrator';
import { HealthAdministratorComplaince, HealthAdministratorDashboard, HealthAdministratorFacilities, HealthAdministratorIncidents, HealthAdministratorLayoutPage, HealthAdministratorStaff, HealthAdministratorStaffAccess, HealthAdministratorStaffInfo } from './pages/healthadministrator';
import { PharmacistChat, PharmacistDashboard, PharmacistMedicalDispensation, PharmacistMedicalDispensationUpdate, PharmacistUserInfo } from './pages/pharmacist';
import PharmacistLayoutPage from './pages/pharmacist/PharmacistLayoutPage';



const App = () =>{

  return (
    <BrowserRouter>
      <Routes>
        
        {/* public routes */}
        <Route path='/' element ={<LayoutPage />}>
          <Route index element ={<HomePage />} />
          <Route path ='login' element ={<LoginPage />} />
          <Route path ='signup' element ={<SignUpPage />} />
          <Route path ='forgot-password' element ={<ForgotPassword />} />
          <Route path ='services' element ={<ServicesPage />} />
          <Route path ='about' element ={<About />} />
          <Route path ='contact' element ={<Contact />} />
          <Route path ='blog' element ={<Blog />} />
          <Route path ='logout' element ={<LogoutPage />} />
        </Route>

      
        {/* patient routes */}
        <Route path='/patient' element ={<PatientLayoutPage/>}>
          <Route index element ={<PatientDashboard/>}/>
          <Route path ='phr' element ={<PatientHealth />} />
          <Route path ='phr/healthupdate' element ={<PatientHealthUpdate />} />
          <Route path ='phr/appointmentupdate/:id' element ={<PatientAppointmentUpdate />} />
          <Route path ='phr/appointmentadd' element ={<PatientAppointmentAdd />} />
          <Route path ='community' element ={<PatientCommunity />} />
          <Route path ='prescriptions' element ={<PatientPrescriptions />} />

          <Route path ='chat' element ={<PharmacistChat />} />

        </Route>

        {/* healthcare routes */}
        <Route path='/healthprovider' element ={<HealthProviderLayoutPage/>}>
          <Route index element ={<HealthProviderDashboard/>}/>
       
          <Route path ='community' element ={<HealthProviderCommunity />} />
          <Route path ='patientappointmentview' element ={<HealthProviderAppointmentView />} />  
          <Route path ='patientappointmentupdate/:id' element ={<HealthProviderAppointmentUpdate />} />
          <Route path ='patients' element ={<HealthProviderPatients />} />
          <Route path ='viewpatientinfo/:id' element ={<HealthProviderPatientInfo />} />
          <Route path ='chat' element ={<PharmacistChat />} />

        </Route>

        
        {/* Admin routes */}
        <Route path='/administrator' element ={<AdminLayoutPage/>}>
          <Route index element ={<AdminDashboard/>}/>
       
          <Route path ='users' element ={<AdminUsers />} />
          <Route path ='healthproviders' element ={<AdminHealthProviders />} />  
          <Route path ='systemconfiguration' element ={<AdminSystemConfiguration />} />
          <Route path ='dataoversight' element ={<AdminDataOversight />} />
          <Route path ='viewuserinfo/:id' element ={<AdminUserInfo />} />
          <Route path ='updateuserinfo/:id' element ={<AdminUserUpdate />} />
          <Route path ='adduser' element ={<AdminUserAddNew />} />
          <Route path ='viewhealthproviderinfo' element ={<AdminHealthProviderInfo />} />
          <Route path ='patientappointmentview' element ={<HealthProviderAppointmentView />} />  
          
          <Route path ='chat' element ={<PharmacistChat />} />

        </Route>

        {/* health Admin routes */}
        <Route path='/healthadministrator' element ={<HealthAdministratorLayoutPage/>}>
          <Route index element ={<HealthAdministratorDashboard/>}/>
       
          <Route path ='facilities' element ={<HealthAdministratorFacilities />} />
          <Route path ='staffmanagement' element ={<HealthAdministratorStaff />} />  
          <Route path ='systemconfiguration' element ={<AdminSystemConfiguration />} />
          <Route path ='viewstaffinfo/:id' element ={<HealthAdministratorStaffInfo />} />
          <Route path ='updatestaffaccess' element ={<HealthAdministratorStaffAccess />} />
          <Route path ='incidents' element ={<HealthAdministratorIncidents />} />
          <Route path ='complaince' element ={<HealthAdministratorComplaince />} />

          <Route path ='chat' element ={<PharmacistChat />} />

        </Route>

         {/* Pharamacist routes */}
         <Route path='/pharmacist' element ={<PharmacistLayoutPage/>}>
          <Route index element ={<PharmacistDashboard/>}/>       
          <Route path ='checkmedicalhistory' element ={<PharmacistUserInfo />} />
          <Route path ='medicaldispense' element ={<PharmacistMedicalDispensation />} />  
          <Route path ='viewpatientinfo/:id' element ={<HealthProviderPatientInfo />} />
          <Route path ='medicaldispenseupdate/:id' element ={<PharmacistMedicalDispensationUpdate />} />
          <Route path ='chat' element ={<PharmacistChat />} />
  

          
        </Route>

      </Routes>
    </BrowserRouter>
  )
}
export default App;
