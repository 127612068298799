import { api } from ".";


export const create_post= async post_data =>{
    const response = await api.post('/createpost', post_data);
    console.log(response);
    const {status, data: {message, error}} = response;
    console.log(error);
    return {status, message, error}
}


export const fetch_topposts = async() =>{    
    try {
        const response = await api.get('/top-posts');
        const data = response.data
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetch_phmbyid =async (id) =>{    
    try {
        const encodedId = encodeURIComponent(id);
        const response = await api.get(`/phmbyid?id=${encodedId}`);
        console.log(response);
        const { status, data } = response;
        return { status, data };   
    } catch (error) {
        console.error('Error:', error.message);
        return null;
    }
}

export const createorupdatephr= async phrdata =>{
    console.log(phrdata);
    const response = await api.post('/cuphm', phrdata);
    console.log(response);
    const {status, data: {message, error}} = response;
    console.log(error);
    return {status, message, error}
}


export const checkSymptoms = async sympqs =>{
    console.log('iam symp',sympqs);
    const encodedId = encodeURIComponent(sympqs);
    const response = await api.get(`/checksymp?symptoms=${encodedId}`);
    const {status, data} = response;
    console.log(data);
    return {status, data}
}

export const createappoint= async post_data =>{
    const response = await api.post('/createappointment', post_data);
    console.log(response);
    const {status, data: {message, error}} = response;
    console.log(error);
    return {status, message, error}
}


export const fetchAppointsbyid =async (id) =>{    
    try {
        const encodedId = encodeURIComponent(id);
        const response = await api.get(`/appbyid?id=${encodedId}`);
        console.log(response);
        const { status, data } = response;
        return { status, data };   
    } catch (error) {
        console.error('Error:', error.message);
        return null;
    }
}

export const fetchAllAppointsbyid =async (id) =>{    
    try {
        const encodedId = encodeURIComponent(id);
        const response = await api.get(`/allappbyid?id=${encodedId}`);
        console.log(response);
        const { status, data } = response;
        return { status, data };   
    } catch (error) {
        console.error('Error:', error.message);
        return null;
    }
}


export const cancelAppbyid =async (id) =>{    
    try {
        const encodedId = encodeURIComponent(id);
        const response = await api.post(`/cancelappbyid?id=${encodedId}`);
        console.log('cancel',response);
        const { status, message } = response;
        return { status, message };   
    } catch (error) {
        console.error('Error:', error.message);
        return null;
    }
}

export const updateAppoint =async (dat) =>{    
    try {


        const response = await api.post('/updateapp', dat);
        console.log('update',response);
        const { status, message } = response;
        return { status, message };   
    } catch (error) {
        console.error('Error:', error.message);
        return null;
    }
}




export const getPresByUserId =async (id) =>{    
    try {
        const encodedId = encodeURIComponent(id);
        const response = await api.get(`/prescriptionsByUserId?id=${encodedId}`);
        console.log('pres',response.data);

        return response.data;   
    } catch (error) {
        console.error('Error:', error.message);
        return null;
    }
}





export const requestRefil =async (dat) =>{    
    try {
        console.log('update',dat);
        const encodedId = encodeURIComponent(dat);
        const response = await api.post(`/updatePrescriptionStatus?id=${encodedId}`);
        console.log('update2',response);
        const { status, message } = response;
        return { status, message };   
    } catch (error) {
        console.error('Error:', error.message);
        return null;
    }    
}

export const presDelivered =async (dat) =>{    
    try {
        console.log('update',dat);
        const encodedId = encodeURIComponent(dat);
        const response = await api.post(`/updatePrescriptionDelStatus?id=${encodedId}`);
        console.log('update2',response);
        const { status, message } = response;
        return { status, message };   
    } catch (error) {
        console.error('Error:', error.message);
        return null;
    }    
}



export const facilitybycountry =async (dat) =>{    
    try {
        console.log(dat);
        const encodedId = encodeURIComponent(dat);
        const response = await api.get(`/facilitiesbycountry?id=${encodedId}`);
        console.log('update',response);
        const { status, message, data  } = response;
        return { status, message, data };   
    } catch (error) {
        console.error('Error:', error.message);
        return null;
    }



}

export const createincident= async post_data =>{
    const response = await api.post('/createincident', post_data);
    console.log(response);
    const {status, data: {message, error}} = response;
    console.log(error);
    return {status, message, error}
}

export const getAllIncidents = async() =>{    
    try {
        const response = await api.get('/getAllIncidents');
        const data = response.data
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}