import React, { useState , useEffect} from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import './assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import '../healthcareprovider/assets/style.css';
import calendarImg from '../../assets/calendarillustration.png'
import surgeryImg from '../../assets/surgeryillustration.png'
import callImg from '../../assets/callillustration.png'
import patient1 from '../../assets/patient1.jpg'
import patient2 from '../../assets/patient2.jpg'
import {NavLink, useNavigate} from 'react-router-dom';

import {getallmessagesbycurrentuser, sendmess} from '../../api/chat'
import { fetch_userbyid } from "../../api/admin";
import { w3cwebsocket as W3CWebSocket } from 'websocket';

function PharmacistChat() {
    const navigate =useNavigate();
  
// Create a WebSocket client instance
const client = new W3CWebSocket('ws://localhost:3001');
const [loading, setLoading] = useState(true);
const storedSessionDataString = localStorage.getItem('session');
const storedSessionData = JSON.parse(storedSessionDataString);
const currentUser = storedSessionData.id;

const [messages, setMessages] = useState([]);
const [selectedUser, setSelectedUser] = useState(null);
const [messageInput, setMessageInput] = useState('');
const [searchQuery, setSearchQuery] = useState('');
const [userMessageMap, setUserMessageMap] = useState(new Map());
const [messageUser, setMessagesUser] = useState([]);
const [messageUserEmpty, setMessageUserEmpty] = useState(true);

useEffect(() => {
  const getallMessages = async () => {
      try {
          const response = await getallmessagesbycurrentuser(currentUser);
      
          if (response.status) {
              const { status, data } = response;
              if (status >= 400) {
                  console.error('Error fetching messages:', data);
              } else {
                  setMessages(data);
              }
          } else {
              console.error('Invalid response:', response);
          }
      } catch (error) {
          console.error('Error fetching messages:', error);
      }
  };

  getallMessages();
}, [currentUser]);



const [refreshCount, setRefreshCount] = useState(0);


const handleRefreshClick = () => {

setRefreshCount((prevCount) => prevCount + 1);
};

useEffect(() => {
 client.onmessage = (message) => {
   const receivedMessage = JSON.parse(message.data);
   setMessages((prevMessages) => [...prevMessages, receivedMessage]);
   handleauto();
 };

 return () => {
   // Clean up WebSocket connection when the component unmounts
  // client.close();
 };
});



messages.forEach((message) => {
const { messaged_by, recieved_by } = message;  
if (!userMessageMap.has(messaged_by)) {
 userMessageMap.set(messaged_by, []);
}  
if (!userMessageMap.has(recieved_by)) {
 userMessageMap.set(recieved_by, []);
}
});

const handleauto = () =>{
messages.forEach((message) => {
const { messaged_by, recieved_by } = message;  
if (!userMessageMap.has(messaged_by)) {
 userMessageMap.set(messaged_by, []);
}  
if (!userMessageMap.has(recieved_by)) {
 userMessageMap.set(recieved_by, []);
}
});

}

userMessageMap.delete(undefined);


useEffect(() => {
const fetchData = async () => {
 try {

   const users = await Promise.all(
     Array.from(userMessageMap.keys()).map(async (currentUser) => {
       const user = await fetch_userbyid(currentUser);
       return user;
     })
   );
   setMessagesUser(users.filter((user) => user));
 } catch (error) {
   console.error('Error fetching data:', error);
 } finally {
   setLoading(false);
 }
};

fetchData();
}, [userMessageMap, refreshCount]);


useEffect(() => {
setMessageUserEmpty(messageUser.length === 0);
}, [messageUser]);


const handleSearch = async () => {
if (searchQuery.trim() !== '') {
 const data = await fetch_userbyid(searchQuery);
if (data && data.length !== 0) {
    // Check if the username already exists in messageUser
 const usernameExists = messageUser.some((user) => user.DisplayName === data.DisplayName);

 if (!usernameExists) {
   setMessagesUser((prevMessageUser) => [...prevMessageUser, data]);
   setSearchQuery(''); // Set searchQuery to empty string to clear it
 } else {
   alert(searchQuery + " already exists in the chat.");
   setSearchQuery('');
 }
 }else{
   //alert(searchQuery +" Not Found");
   setSearchQuery('');

 }

}
}

const handleSearchButtonClick = () => {
   handleSearch();
 };
      
 const handleSearchQueryChange = (e) => {
   setSearchQuery(e.target.value);
     };

     const sendMessage = async () => {
       const messageData = {messaged_by : currentUser, recieved_by : selectedUser.UserID,message: messageInput }
   
       
       try {
        const response = await sendmess(messageData);
    
        if (response && response.status) {
            const { status, data } = response;
            // Handle the response status and data
            if (status >= 400) {
                // Handle error
                console.error('Error fetching messages:', data);
            } else {
              if(status === 200){
                setMessageInput('');
                console.log("Message Successfully Sent")
                client.send(JSON.stringify(messageData));
            }else{
                alert("Failed to send message. Please try after some time")
            }
            }
        } else {
            // Handle the case where the response is invalid
            console.error('Invalid response:', response);
        }
    } catch (error) {
        // Handle any errors that occur during the request
        console.error('Error fetching messages:', error);
    }       
      
     };

const [filteredMessages, setFilteredMessages] = useState([]);

useEffect(() => {
const filterMessages = () => {

 if (selectedUser) {
   const filteredMessages = messages.filter(
     (message) =>
     (message.messaged_by == selectedUser.UserID && message.recieved_by == currentUser) ||
     (message.messaged_by == currentUser && message.recieved_by == selectedUser.UserID)
 
   );
   setFilteredMessages(filteredMessages);
 }
};

filterMessages();
}, [refreshCount]);

if (loading) {
return <div>Loading...</div>;
}else{
return (
//left
<div className="healthcare-dashboard">
  

<div className="desktop-wrapper">

    <div className="desktop">
        <div className='community-content'>
        
        <div className='row'>
                            <div className='col-sm'>
                                
                            <div className='row'>
                                    <div className='healthforum-add'>
                                  
                                  <div className='row'>
                                      <div className='add-healthforum'>
                                        <div className="health-form2">
                                        <div className='row'>
                                        
                                        <div className="container">
        <div className="row">
            <div className="col-md-4">
          
                <div className="chat-members">
                <div className="search-user">
                
   <button  onClick={handleRefreshClick}>Refresh</button>
 
                <div className="input-box">
                
                <input
                   type="text"
                   value={searchQuery}
                   onChange={handleSearchQueryChange}
                   placeholder="Search user by username"
                />
                <button className='health-btn' onClick={handleSearchButtonClick}>Search</button>
     
        </div>
        <br/>
   
           </div>
                    <h4>Chat Members</h4>

                    {
                      
                      messageUser.filter((user, index, self) =>
                      index === self.findIndex((u) => u.UserID === user.UserID)).map((user) => {
                        if (user.UserID !== currentUser) {
                          return (
                            <div
                              key={user.UserID}
                              className={`user-item ${selectedUser && selectedUser.id === user.id ? 'active' : ''}`}
                              onClick={() => setSelectedUser(user)}
                            >
                              {user.DisplayName} 
                            </div>
                          );
                        }
                        return null;
                 
})
}


                  
                </div>
            </div>
            <div className="col-md-8">
            {selectedUser ? (
 <>
   <div className="chat-window">
     User: {selectedUser.DisplayName}
     <div className="message">
     {filteredMessages.map((message) => (
       <div
         key={message.id}
         className={`message-item ${message.messaged_by === currentUser ? 'sent' : 'received'}`}
       >
         {message.message}
       </div>
     ))}
  
   </div>
   </div>
   
   <div className="input-box">
   <input
       type="text"
       value={messageInput}
       onChange={(e) => setMessageInput(e.target.value)}
       placeholder="Type your message..."
     />
     <button className="health-btn" onClick={sendMessage}>Send</button>
        </div>

 </>
) : (
 <div className="placehold">Select a user to start chatting</div>
)}
         
            </div>
        </div>
    </div>
      </div>    </div>    </div>    </div>    </div>    </div>    </div>    </div>
    <div className='row'>
   <div className="container" style={{margin : '20px 100px 20px 100px',height : '70%'}}>
       <div className="user-list">
           
        
         



       </div>

{/* right */}

     <div className="chat-area">

     </div>
   </div>  </div>  </div>  </div>  </div>  </div>
 );
}
};


export default PharmacistChat;
