import { useEffect } from 'react';
import {routes} from '../../utils/routes';
import {Outlet, useNavigate} from 'react-router-dom';
import {FooterComponent,  NavigationBarComponent} from '../components';

const HealthAdministratorLayoutPage = () => {
  const navigate = useNavigate();
  const links =[
    {to: '/healthadministrator', label: 'Home'},
    {to: '/healthadministrator/facilities', label: 'Facilities'},
    {to: '/healthadministrator/staffmanagement', label: 'Staff Management'},
    {to: '/healthadministrator/incidents', label: 'Incidents'},
    {to: '/healthadministrator/complaince', label: 'Complaince'},
    {to: '/healthadministrator/chat', label: 'Chat'},
    {to: '/logout', label: 'Logout'},
  ]

  useEffect(() =>{
    const {isLoggedIn, role} =JSON.parse(localStorage.getItem('session')) || {};
    if(!isLoggedIn) navigate('/login', {replace: true});
    if(role !='4') navigate(routes[role]|| '/logout', {replace: true});
  },[]);
  return (
    <>
    <NavigationBarComponent links={links} heading={''}/>
    <Outlet />
    <FooterComponent />
    </>
  )
}

export default HealthAdministratorLayoutPage;