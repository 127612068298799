import {Outlet} from 'react-router-dom';
import {FooterComponent, NavigationBarComponent} from '../components'; 

const LayoutPage = () => {
  const links =[
    {to: '/', label: 'Home'},
    {to: '/about', label: 'About'},
    {to: '/services', label: 'Services'},
    {to: '/contact', label: 'Contact'},
    {to: '/blog', label: 'Blog'},
    {to: '/login', label: 'Login'},
    {to: '/signup', label: 'Register'},
  ]
  return (
    <>
      <NavigationBarComponent links={links}/>
      <Outlet />
      <FooterComponent />
    </>
  )
}

export default LayoutPage;