import React from "react";
import "./servicesstyle.css";
import {useForm} from 'react-hook-form';
import  { useState } from 'react';
const Contact = () => {
  return (
    <div class="container">

    <div class="row">
        <div class="col">
            <h2>Contact Us</h2>
            <div className="card">
                <div className="card-text centercont">
         
            <address>34 Street Name, Neywork,<br/>United States</address>
            <p>Phone: +1 (222) 345 6789<br/>Email: info@smarthealthhub.com</p>
            </div>
            </div>
        </div>

    
   
</div>

  </div>

  );
};
export default Contact;