import React, { useEffect, useState } from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import '../healthcareprovider/assets/style.css';
import calendarImg from '../../assets/calendarillustration.png'
import surgeryImg from '../../assets/surgeryillustration.png'
import callImg from '../../assets/callillustration.png'
import doctorImg from '../../assets/doctor.webp'
import userImg from '../../assets/user.webp'
import patient1 from '../../assets/patient1.jpg'
import patient2 from '../../assets/patient2.jpg'
import {useForm} from 'react-hook-form';
import {NavLink, useNavigate} from 'react-router-dom';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import {getappcounts, getuserscounts } from '../../api/admin';

function AdminDashboard() {

    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const fields =[
      [       
        
        {fieldName: 'apd', label: 'Select Date',  validations: {
            required: "Date Required*"
          }, type: 'date'},
      ],
  
      
    ]

    const [userCount, setUserCount] = useState(null);
    const [appCount, setAppCount] = useState(null);

    useEffect(() => {
      
      const fetchUsercount = async () => {
        try {
          const data = await getuserscounts();
          setUserCount(data);
          const data2 = await getappcounts();
          setAppCount(data2);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

    fetchUsercount();
 
    });
 

  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">Overview</div>
                
            <div className='row'>
                            <div className='col-sm-10'>
                                <div className='healthproviderdash-left'>
                                    <div className='row'>
                                        <div className='col-sm'>
            <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={calendarImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>Appointments</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>{appCount ? appCount : 0}</h6>

                  </div>

                </div>
             
            </div>
            </div>
              

                                        </div>
                                        <div className='col-sm'>
                                        <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={surgeryImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>Surgeries</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>0</h6>

                  </div>

                </div>
             
            </div>
            </div>
                                            </div>
                                            <div className='col-sm'>
                                            <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={callImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h5 className='newAcc'>Call Consultancy</h5>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>90</h6>

                  </div>

                </div>
             
            </div>
            </div>  
                                            </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-sm'>
            <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={userImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>Total Users</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>{userCount ? userCount : 0}</h6>

                  </div>

                </div>
             
            </div>
            </div>
              

                                        </div>
                                        {/* <div className='col-sm'>
                                        <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={doctorImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>Total Health Providers</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>60</h6>

                  </div>

                </div>
             
            </div>
            </div>
                                            </div> */}
                                            

                                    </div>
                                </div>
                                
                            
                              
                            </div>
                            <div className='col-sm-4'>

                           


                                {/* <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                        <form className="health-form"  onSubmit={handleSubmit(handleSignup)}  style={{height: 'max-content'}} name='login-form'>
          <h2>Generate Report</h2><br />
         
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}

          </div>)} 
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Generate</button></p>
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
    
        </form>

                                        </div>
                                    </div>

                                </div> */}


                        </div>
               
                   

                </div>

                
                </div>
        
                
                <br /><br /><br /><br /><br />
            </div>
        </div>

     
    </div>

);

}
export default AdminDashboard;
