import {NavLink, useNavigate} from 'react-router-dom';
import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';
import './assets/style.css';
import heartImg from '../../assets/heart.png';
import sugarImg from '../../assets/sugar.png';
import armImg from '../../assets/arm.png';
import React, { useState } from 'react';
import {useForm} from 'react-hook-form';
import { createappoint } from '../../api/common';

import { FormInputComponent, LoadingAnimationComponent } from '../components';

const PatientAppointmentAdd = () => {
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const [serverSuccess, setServerSuccess] =useState(null);
    const storedSessionDataString = localStorage.getItem('session');
    const storedSessionData = JSON.parse(storedSessionDataString);
    const currentUser = storedSessionData.id;
    console.log(currentUser);
    const navigate =useNavigate();

    const fields =[
        [        {fieldName: 'apdate', label: 'Appointment Date',  validations: {
          required: "Appointment Date Required*"
        }, type: 'date'},

        {fieldName: 'apdesc', label: 'Description', type :'textarea', validations: {
          required: "Appointment Description Required*"
        }},
  
        ],
    
        
      ]
      const handleSignup =async values =>{
        setServerError(null);
        setSubmitting(true);
        setServerSuccess(null);
        const form = document.forms['appoint-form'];
        try {
          [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
            values['userid'] = currentUser

          const {status, message} =await createappoint(values);
    
          if (status == 201) {
            setServerSuccess(message)
          };
          if (status >= 400) throw new Error(message);        
          else {
              reset();           
              setTimeout(function() {
                navigate('/patient', { replace: true });
            }, 2000);
          }
        } catch (error) {
          if(error.response) setServerError(error.response.data.message);
          else setServerError(error.message);
        }
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
      }
    
    return (
        <div className="section">
        <section className="hero">
      <div className="">
    
        <div className="logcolumn">
        <form className="appoint-form"  onSubmit={handleSubmit(handleSignup)}  style={{height: 'max-content'}} name='appoint-form'>
          <h2>Schedule New Appointement</h2><br />
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Book Appointement</button></p>
            <br /><br />
 
       </>)}
       {serverError &&<div className="error-alert">{serverError}</div>}
          {serverSuccess &&<div className="success-alert">{serverSuccess}</div>}
         
          </div>
        </form>
        </div>
 
       
      </div>
    </section>

      
        <br/><br/><br/><br/><br/><br/>
      </div>
       

    );

}

export default PatientAppointmentAdd;