import React, { useState } from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import { useForm } from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import '../healthcareprovider/assets/style.css';
import calendarImg from '../../assets/calendarillustration.png'
import surgeryImg from '../../assets/surgeryillustration.png'
import callImg from '../../assets/callillustration.png'
import patient1 from '../../assets/patient1.jpg'
import patient2 from '../../assets/patient2.jpg'
import { NavLink, useNavigate } from 'react-router-dom';
import { fetch_userbyid, fetch_userbyname } from '../../api/admin';

function AdminUsers() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/administrator/adduser', { replace: true });
  };
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const [serverError, setServerError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]); 
  const fields = [
    [


      {
        fieldName: 'userId', label: 'User Name/ID', validations: {
          required: "User Name/ID Required*"
        }
      },

    ],


  ]

  const handlesearch = async values => {
    setServerError(null);
    setSubmitting(true);
    const form = document.forms['search-form'];
    try {
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled = true);

        // Check if the value is a valid user ID
        const userId = parseInt(values.userId);
        console.log(userId);
        let resultUsers;
        if (!isNaN(userId)) {
            // Search for user by ID
            const userById = await fetch_userbyid(userId);
            resultUsers = userById ? [userById] : []; // Convert single user to an array
        } else {
            // Search for user by name
            console.log(values.userId);
            resultUsers = await fetch_userbyname(values.userId);
        }

        if (Array.isArray(resultUsers)) {
            resultUsers.forEach(resultUser => {
                // Check for duplicates based on user ID
                if (!users.some(user => user.id === resultUser.id)) {
                    // Add the fetched user to the list of users
                    setUsers(prevUsers => [...prevUsers, resultUser]);
                }
            });
        }
        console.log('Fetched users:', resultUsers);

    } catch (error) {
        if (error.response) setServerError(error.response.data.message);
        else setServerError(error.message);
    } finally {
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled = false);
    }
}


const UserComponent = ({ user }) => (
  <div className='col-sm'>
    <div className='capsule'>
      <div className='row'>
        <div className='col-sm-4'>
          <div className='cap-image'>
            <img className="capimg" alt="Group" src={patient1} />
          </div>
        </div>
        <div className='col-sm-8'>
          <div className='row'>
            <h6>{user.DisplayName}</h6>
          </div>
          <div className='row'>
            <p><NavLink to={`/administrator/viewuserinfo/${user.UserID}`}>View User Information</NavLink></p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

  return (
    <div className="healthcare-dashboard">


      <div className="desktop-wrapper">

        <div className="desktop">
          <div className='community-content'>

            <div className='row'>
              <div className='col-sm-8'>

                <div className='row'>
                  <div className='healthforum-add'>

                    <div className='row'>
                      <div className='add-healthforum'>
                        <div className="health-form2">
                          <h3 className='newAcc'>Users</h3><br />
                          {users.reduce((rows, user, index) => {
    if (index % 2 === 0) {
      rows.push([]);
    }
    rows[rows.length - 1].push(user);
    return rows;
  }, []).map((row, rowIndex) => (
    <div key={rowIndex} className='row'>
      {row.map(user => (
        <UserComponent key={user.id} user={user} />
      ))}
    </div>
  ))}
                        </div>

                      </div>
                    </div>

                  </div>
                </div>



              </div>
              <div className='col-sm-4'>

                <div className='healthforum-add'>

                  <div className='row'>
                    <div className='add-healthforum'>

                      <form className="health-form" onSubmit={handleSubmit(handlesearch)} style={{ height: 'max-content' }} name='search-form'>

                        <h2>Search Users</h2><br />

                        {fields.map((row, index) => <div key={index} className='form-row'>
                          {row.map(({ fieldName, type, label, options, validations, placeholder }, index) => (<FormInputComponent name={fieldName} label={label} key={index} type={type} options={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder} />))}
                        </div>)}
                        <div className="options-end">
                          <br />
                          {submitting ? <LoadingAnimationComponent /> : (<>
                            <p className='newAcc'> <button className="health-btn" type="submit">Search</button></p>

                            <p className='newAcc'>Or</p>
                            <p className='newAcc'> <button className="health-btn" onClick={handleClick}>Add New User</button></p>
                            <br /><br />

                          </>)}
                          {serverError && <div className="error-alert">{serverError}</div>}

                        </div>
                      </form>

                    </div>
                  </div>

                </div>

              </div>



            </div>
          </div>


          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>


    </div>

  );

}
export default AdminUsers;
