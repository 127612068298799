import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';
import '../patient/assets/style.css';
import '../healthcareprovider/assets/style.css';
import heartImg from '../../assets/heart.png';
import sugarImg from '../../assets/sugar.png';
import armImg from '../../assets/arm.png';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation , useParams  } from 'react-router-dom';
import patient2 from '../../assets/patient2.jpg'
import {NavLink} from 'react-router-dom';
import { fetch_userbyid } from '../../api/admin';
import {getPresByUserId, requestRefil } from '../../api/common';
const AdminUserInfo = () => {
 
    const [symptoms, setSymptoms] = useState([]);
    const navigate = useNavigate();
 
    const [presData, setPresData] = useState(null);
    const { id } = useParams();

        
    const formatDate =timestamp =>{
        const new_date =new Date(timestamp);
        const format_2dgt =v =>parseInt(v) <10? `0${v}`: v;
        const days =['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const year =new_date.getFullYear();
        const month =format_2dgt(new_date.getMonth()+1);
        const day =new_date.getDay()-1;
        const date =format_2dgt(new_date.getDate());
        return `${days[day]}, ${date}-${month}-${year}`;
    }


  const [userData, setUserData] = useState(null);

  useEffect(() => {
    
    const fetchUserData = async () => {
      try {
        console.log(id);
        const data = await fetch_userbyid(id);
        console.log(data);
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
if(!userData){
    fetchUserData();
}
  });

  useEffect(() => {
    
    const fetchUserData = async () => {
      try {
        console.log(id);
          const data2 = await getPresByUserId(id);
        console.log('data2',data2)
        setPresData(data2)
    
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  },[id]);


  return (
    <div>
      {userData ? (

<div className="healthcare-dashboard">


<div className="desktop-wrapper">

    <div className="desktop">
        <div className='community-content'>
            <div className="community-head">Patient Information</div>

            <div className='row'>
                <div className='col-sm-8'>

                    <div className='patient-leftblock'>
                        <div className='row'>
                        <div className='capsule2'>
 <div className='row'>
  <div className='col-sm-2'>
      <div className='healthprovider-image'>
          
   <img className="capimg" alt="Group" src={patient2} />
                
      </div>
  </div>
  <div className='col-sm-8'>
      <div className='row'>
          <h5>Patient Name : {userData.DisplayName}</h5>
      </div>
      
      <div className='row'>
      <h6>Patient Id : {userData.UserID}</h6>
      <h6>Joined At : {`${formatDate(userData.created_at)}`}</h6>
      

      </div>

  </div>
  <div className='col-sm'>
            
                    {/* <p ><NavLink to="/administrator/viewuserinfo" > &nbsp;Disable Account</NavLink></p> */}

                    <p ><NavLink to={`/administrator/updateuserinfo/${userData.UserID}`}> &nbsp;Update Account</NavLink></p>
                  </div>
</div>
</div>

                        </div>
                        <div className='row'>
                            <div className='col-sm'>
                            <div className='card-prop'>
                                    <div className='row'>
                                        <div className='col-sm'>
                                        <img className="capimg" alt="Group" src={sugarImg} />
                                        </div>
                                        <div className='col-sm'>
                                        <h5>Blood Sugar</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="text-value"><h3 className='arc'>{userData.blood_sugar ? (userData.blood_sugar) : 0}</h3> &nbsp;<a>mg / dL </a></div>                                                    
                                    </div>
                                    <div className='row'>
                                        <div><h6 className="text-status">Normal</h6></div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-sm'>
                            <div className='card-prop'>
                                    <div className='row'>
                                        <div className='col-sm'>
                                        <img className="capimg" alt="Group" src={heartImg} />
                                        </div>
                                        <div className='col-sm'>
                                        <h5>Heart Rate</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="text-value"><h3 className='arc'>{userData.heart_rate ? (userData.heart_rate) : 0}</h3> &nbsp;<a>bpm </a></div>                                                    
                                    </div>
                                    <div className='row'>
                                        <div><h6 className="text-status">Normal</h6></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm'>
                            <div className='card-prop'>
                                    <div className='row'>
                                        <div className='col-sm'>
                                        <img className="capimg" alt="Group" src={armImg} />
                                        </div>
                                        <div className='col-sm'>
                                        <h5>Blood Pressure</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="text-value"><h3 className='arc'>{userData.blood_pressure ? (userData.blood_pressure) : 0}</h3> &nbsp;<a>/ 72 mmhg </a></div>                                                    
                                    </div>
                                    <div className='row'>
                                        <div><h6 className="text-status">Normal</h6></div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <br/> <br/><br/>
                        <div className='row'>
                            
                          
                        <div className='thread thread-cust'>
                        <h4>Previous Prescription History</h4>
                        <br/>
                            <div className='row'>
                              
                                <div className='row'>
                                    <div className='thread-desc'>
                                 
                                         
                                          

                                    <table class="table">
    <thead>
        <tr>
            <th>Medicine Name</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>Instructions</th>
        </tr>
    </thead>
    <tbody id="prescriptions-table-body">
    {presData ? presData.map((prescription, index) => (
            <tr key={index}>
              <td>{prescription.MedicineName}</td>
              <td>{prescription.Dosage}</td>
              <td>{prescription.Frequency}</td>
              <td>{prescription.Instructions}</td>
            </tr>
          )) : <div></div>}
    </tbody>
</table>


                                            


                                    </div>                                                
                                </div>
                          

                            </div>
                        
                        </div>
                        </div>

                    </div>

                </div>
                <div className='col-sm-4'>
                    <div className='patient-rightblock'>
                        <h4 className='newAcc'>BMI Information</h4>
                        <br />
                        <center>
                            <div className='row'>
                                <div className='col-sm'>
                                    <div className="overlap-4">
                                        <div className="text-wrapper-15">Weight</div>
                                        <div className="text-wrapper-17">{userData.weight ? (userData.weight) : 0} kg</div>
                                        <div className="group-3">
                                            <div className="rectangle-3" />
                                            <div className="rectangle-4" />
                                            <div className="rectangle-5" />
                                            <div className="rectangle-6" />
                                            <div className="rectangle-7" />
                                            <div className="rectangle-8" />
                                            <div className="rectangle-9" />
                                            <div className="rectangle-10" />
                                            <div className="rectangle-11" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm'>
                                    <div className="overlap-3">
                                        <div className="text-wrapper-15">Height</div>
                                        <div className="text-wrapper-16">{userData.height ? (userData.height) : 0} cm</div>
                                        <div className="group-3">
                                            <div className="rectangle-3" />
                                            <div className="rectangle-4" />
                                            <div className="rectangle-5" />
                                            <div className="rectangle-6" />
                                            <div className="rectangle-7" />
                                            <div className="rectangle-8" />
                                            <div className="rectangle-9" />
                                            <div className="rectangle-10" />
                                            <div className="rectangle-11" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </center>
                        <br />

                        {/* <div className='row'>
                            <center>
                                <div className="overlap-group-2m">

                                    <div className="text-wrapper-7">24.9</div>
                                    <div className="text-wrapper-8">Body Mass Index (BMI)</div>
                                    <div className="div-wrapper">
                                        <div className="text-wrapper-9">You’re Healthy</div>
                                    </div>
                                    <div className="group-2">
                                        <div className="text-wrapper-10">15</div>
                                        <div className="text-wrapper-11">18.5</div>
                                        <div className="text-wrapper-12">25</div>
                                        <div className="text-wrapper-13">30</div>
                                        <div className="text-wrapper-14">40</div>
                                        <div className="rectangle" />
                                        <div className="rectangle-2" />
                                    </div>

                                </div>
                            </center>
                        </div> */}

                    </div>

                </div>
            </div>  </div>  </div>
</div>  </div>

      ) : (
        // Render loading indicator or message
        <p>Loading user data...</p>
      )}
    </div>
  );
  
    // return (
    //     <div className="healthcare-dashboard">


    //         <div className="desktop-wrapper">

    //             <div className="desktop">
    //                 <div className='community-content'>
    //                     <div className="community-head">User Information</div>

    //                     <div className='row'>
    //                         <div className='col-sm-8'>

    //                             <div className='patient-leftblock'>
    //                                 <div className='row'>
    //                                 <div className='capsule2'>
    //          <div className='row'>
    //           <div className='col-sm-2'>
    //               <div className='healthprovider-image'>
                      
    //            <img className="capimg" alt="Group" src={patient2} />
                            
    //               </div>
    //           </div>
    //           <div className='col-sm-8'>
    //               <div className='row'>
    //                   <h5>User Name : Joseph</h5>
    //               </div>
                  
    //               <div className='row'>
    //               <h6>User Id : PA00001</h6>
    //               <h6>User Type : Patient</h6>
    //               <h6>Joined At : 12-March-2024 12:00 AM</h6>               

    //               </div>
                 
                  
    //           </div>
    //           <div className='col-sm'>
            
    //                 <p ><NavLink to="/administrator/viewuserinfo" > &nbsp;Disable Account</NavLink></p>

    //                 <p ><NavLink to="/administrator/updateuserinfo" > &nbsp;Update Account</NavLink></p>
    //               </div>
    //       </div>
    //   </div>
    //                                 </div>
    //                                 <div className='row'>
    //                                     <div className='col-sm'>
    //                                     <div className='card-prop'>
    //                                             <div className='row'>
    //                                                 <div className='col-sm'>
    //                                                 <img className="capimg" alt="Group" src={sugarImg} />
    //                                                 </div>
    //                                                 <div className='col-sm'>
    //                                                 <h5>Blood Sugar</h5>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='row'>
    //                                                 <div className="text-value"><h3 className='arc'>80</h3> &nbsp;<a>mg / dL </a></div>                                                    
    //                                             </div>
    //                                             <div className='row'>
    //                                                 <div><h6 className="text-status">Normal</h6></div>
    //                                             </div>
    //                                         </div>

    //                                     </div>
    //                                     <div className='col-sm'>
    //                                     <div className='card-prop'>
    //                                             <div className='row'>
    //                                                 <div className='col-sm'>
    //                                                 <img className="capimg" alt="Group" src={heartImg} />
    //                                                 </div>
    //                                                 <div className='col-sm'>
    //                                                 <h5>Heart Rate</h5>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='row'>
    //                                                 <div className="text-value"><h3 className='arc'>98</h3> &nbsp;<a>bpm </a></div>                                                    
    //                                             </div>
    //                                             <div className='row'>
    //                                                 <div><h6 className="text-status">Normal</h6></div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                     <div className='col-sm'>
    //                                     <div className='card-prop'>
    //                                             <div className='row'>
    //                                                 <div className='col-sm'>
    //                                                 <img className="capimg" alt="Group" src={armImg} />
    //                                                 </div>
    //                                                 <div className='col-sm'>
    //                                                 <h5>Blood Pressure</h5>
    //                                                 </div>
    //                                             </div>
    //                                             <div className='row'>
    //                                                 <div className="text-value"><h3 className='arc'>102</h3> &nbsp;<a>/ 72 mmhg </a></div>                                                    
    //                                             </div>
    //                                             <div className='row'>
    //                                                 <div><h6 className="text-status">Normal</h6></div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    

    //                                 </div>
    //                                 <br/> <br/><br/>
    //                                 <div className='row'>
                                        
                                      
    //                                 <div className='thread thread-cust'>
    //                                 <h4>Previous Prescription History</h4>
    //                                 <br/>
    //                                     <div className='row'>
                                          
    //                                         <div className='row'>
    //                                             <div className='thread-desc'>
                                             
                                                     
                                                      

    // <table class="table">
    //   <thead>
    //     <tr>
    //       <th>Medicine Name</th>
    //       <th>Dosage</th>
    //       <th>Frequency</th>
    //       <th>Instructions</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     <tr>
    //       <td>Vitamins</td>
    //       <td>500mg</td>
    //       <td>Take 1 tablet for every 4 hours</td>
    //       <td>Take with food to avoid stomach upset.</td>
    //     </tr>
    //     <tr>
    //       <td>Calcium</td>
    //       <td>500mg</td>
    //       <td>Take 1 tablet for every 4 hours</td>
    //       <td>Do not exceed 4,000 mg in 24 hours.</td>
    //     </tr>
    //     <tr>
    //       <td>Insulin</td>
    //       <td>500mg</td>
    //       <td>Take 1 tablet for morning and night</td>
    //       <td>If symptoms persist, consult a healthcare professional.</td>
    //     </tr>

    //   </tbody>
    // </table>
 

                                                        


    //                                             </div>                                                
    //                                         </div>
    //                                         <div className='row'>
    //                                             <div className='thread-by'>
    //                                                 <p>Prescribed by Dr.John on Date 2024-02-18 23:54</p>
    //                                             </div>                                                
    //                                         </div>

    //                                     </div>
                                    
    //                                 </div>
    //                                 </div>

    //                             </div>

    //                         </div>
    //                         <div className='col-sm-4'>
    //                             <div className='patient-rightblock'>
    //                                 <h4 className='newAcc'>BMI Information</h4>
    //                                 <br />
    //                                 <center>
    //                                     <div className='row'>
    //                                         <div className='col-sm'>
    //                                             <div className="overlap-4">
    //                                                 <div className="text-wrapper-15">Weight</div>
    //                                                 <div className="text-wrapper-17">72 kg</div>
    //                                                 <div className="group-3">
    //                                                     <div className="rectangle-3" />
    //                                                     <div className="rectangle-4" />
    //                                                     <div className="rectangle-5" />
    //                                                     <div className="rectangle-6" />
    //                                                     <div className="rectangle-7" />
    //                                                     <div className="rectangle-8" />
    //                                                     <div className="rectangle-9" />
    //                                                     <div className="rectangle-10" />
    //                                                     <div className="rectangle-11" />
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                         <div className='col-sm'>
    //                                             <div className="overlap-3">
    //                                                 <div className="text-wrapper-15">Height</div>
    //                                                 <div className="text-wrapper-16">170 cm</div>
    //                                                 <div className="group-3">
    //                                                     <div className="rectangle-3" />
    //                                                     <div className="rectangle-4" />
    //                                                     <div className="rectangle-5" />
    //                                                     <div className="rectangle-6" />
    //                                                     <div className="rectangle-7" />
    //                                                     <div className="rectangle-8" />
    //                                                     <div className="rectangle-9" />
    //                                                     <div className="rectangle-10" />
    //                                                     <div className="rectangle-11" />
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </center>
    //                                 <br />

    //                                 <div className='row'>
    //                                     <center>
    //                                         <div className="overlap-group-2">

    //                                             <div className="text-wrapper-7">24.9</div>
    //                                             <div className="text-wrapper-8">Body Mass Index (BMI)</div>
    //                                             <div className="div-wrapper">
    //                                                 <div className="text-wrapper-9">You’re Healthy</div>
    //                                             </div>
    //                                             <div className="group-2">
    //                                                 <div className="text-wrapper-10">15</div>
    //                                                 <div className="text-wrapper-11">18.5</div>
    //                                                 <div className="text-wrapper-12">25</div>
    //                                                 <div className="text-wrapper-13">30</div>
    //                                                 <div className="text-wrapper-14">40</div>
    //                                                 <div className="rectangle" />
    //                                                 <div className="rectangle-2" />
    //                                             </div>

    //                                         </div>
    //                                     </center>
    //                                 </div>

    //                             </div>

    //                         </div>
    //                     </div>  </div>  </div>
    //         </div>  </div>
    // );

}

export default AdminUserInfo;