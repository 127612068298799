import React, { useState, useEffect } from 'react';import '../commonstyle.css';
import '../patient/assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import {NavLink, useNavigate} from 'react-router-dom';
import {getallprescriptions} from '../../api/admin';
function PharmacistMedicalDispensation() {
    const navigate =useNavigate();

    const handleClick = () => {
        navigate('/pharmacist/medicaldispenseupdate', { replace: true });
    };
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const fields =[
      [       
        
      
      {fieldName: 'heartrate', label: 'Dispense Id', validations: {
        required: "Dispense Id Required*"
      }},

      ],
  
      
    ]

    const [presData, setPresData] = useState(null);
    useEffect(() => {
      
      const fetchPresData = async () => {
        try {
            const data2 = await getallprescriptions();
          console.log('data2',data2)
          setPresData(data2)
      
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
  
      if(!presData){
      fetchPresData();
      }
    },);


    


  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">New Medicine Requests</div>
                
            <div className='row'>
                            <div className='col-sm-12'>
                                
                                <div className='view-threads'>
                                    <div className='thread'>
                                        <div className='row'>
                                        <table class="table table-bordered">
    <thead>
        <tr>
        <th>User Id</th>
        <th>User Name</th>
            <th>Medicine Name</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>Instructions</th>
            <th>Status</th>
        </tr>
    </thead>
    <tbody id="prescriptions-table-body">
    {presData ? presData.map((prescription, index) => (
            <tr key={index}>
               <td>{prescription.UserID}</td>
               <td>{prescription.DisplayName}</td>
              <td>{prescription.MedicineName}</td>
              <td>{prescription.Dosage}</td>
              <td>{prescription.Frequency}</td>
              <td>{prescription.Instructions}</td>
              <td>{prescription.StatusName}</td>
              <td>
                {prescription.PrescriptionStatusID !== 4 && (
                                    <p><NavLink to={`/pharmacist/medicaldispenseupdate/${prescription.PrescriptionID}`}>Update</NavLink></p>

                )}
            </td>
            </tr>
          )) : <div></div>}
    </tbody>
</table>
                                        </div>
                                    
                                    </div>
                                
                                    <p className="communityline" > </p>
                                    <br/>
                                    {/* <div className="community-head">Previous Medicine Requests</div>
                                    <div className='thread'>
                                    <div className='row'>
                                        <table class="table table-bordered">
    <thead>
      <tr>
        <th>User Name</th>
        <th>Doctor Name</th>
        <th>Prescription Details</th>
        <th>Address</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>John Doe</td>
        <td>Dr. Smith</td>
        <td>Medication A - 10mg, Medication B - 20mg</td>
        <td>123 Main St, Anytown, USA</td>
        <td>Completed</td> 
      </tr>
      <tr>
        <td>Jane Smith</td>
        <td>Dr. Johnson</td>
        <td>Medication C - 30mg, Medication D - 40mg</td>
        <td>456 Elm St, Othertown, USA</td>
        <td>Completed</td> 
      </tr>
    
    </tbody>
  </table>
                                        </div>
                                    
                                    </div> */}
                                </div>
                          
                              
                            </div>
                            {/* <div className='col-sm-4'>

                                <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                        <form className="health-form"  onSubmit={handleSubmit(handleSignup)}  style={{height: 'max-content'}} name='login-form'>
          <h2>Check Dispensed Medicine Status</h2><br />

          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Search</button></p>
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>

                                        </div>
                                    </div>

                                </div>

                        </div> */}
               
                   

                </div>
                </div>
        
                

            </div>
        </div>


    </div>

);

}
export default PharmacistMedicalDispensation;
