import React from "react";
import "./servicesstyle.css";

const Blog = () => {
  return (
    <div class="frame">
      <iframe src="https://www.axk8159.uta.cloud/" width="100%" height="100%" frameBorder="0"></iframe>


  </div>
  
  );
};
export default Blog;