import React, { useState } from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import '../healthcareprovider/assets/style.css';
import calendarImg from '../../assets/calendarillustration.png'
import surgeryImg from '../../assets/surgeryillustration.png'
import callImg from '../../assets/callillustration.png'
import patient1 from '../../assets/patient1.jpg'
import patient2 from '../../assets/patient2.jpg'
import {NavLink, useNavigate} from 'react-router-dom';
import { fetch_userbyid } from '../../api/admin';

function AdminHealthProviders() {
    const navigate =useNavigate();
    const handleClick = () => {
        navigate('/administrator/adduser', { replace: true });
    };
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const fields =[
      [       
        

      {fieldName: 'searchuserid', label: 'User Name/ID',  validations: {
        required: "User Name/ID Required*"
      }},

      ],
  
      
    ]

    const handleSignup =async values =>{
        setServerError(null);
        setSubmitting(true);
        const form =document.forms['signup-form'];
        try {
          [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
          delete values.cpassword;
          const {status, message} =await create_user(values);
    
          if(status >=400) throw new Error(message);
          else {
            reset();
            //navigate('/login', {replace: true});
          }
        } catch (error) {
          if(error.response) setServerError(error.response.data.message);
          else setServerError(error.message);
        }
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
      }

        const fetchUser = async value =>{
        try {
          const data = await fetch_userbyid(value);
          setUser(data[0]);
          setEditedUser(data[0]);
        } catch (error) {
          console.error(error);
          if(error.response) setServerError(error.response.data.message);
          else setServerError(error.message);
        }
      };
    

  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                
            <div className='row'>
                            <div className='col-sm-8'>
                                
                            <div className='row'>
                                    <div className='healthforum-add'>
                                  
                                  <div className='row'>
                                      <div className='add-healthforum'>
                                        <div className="health-form2">
                                        <h3 className='newAcc'>Recent Search History</h3><br />
                                        <div className='row'>
                                        <div className='col-sm'>
                                        <div className='capsule'>
             <div className='row'>
              <div className='col-sm-4'>
                  <div className='cap-image'>
                      
               <img className="capimg" alt="Group" src={patient1} />
                            
                  </div>
              </div>
              <div className='col-sm-8'>
                  <div className='row'>
                      <h6>Dr.Joseph</h6>
                  </div>
                  
                  <div className='row'>

                  <p className=''><NavLink to="/administrator/viewuserinfo" > &nbsp;View User Information</NavLink></p>

                  </div>

              </div>
           
          </div>
      </div>
      </div>
      <div className='col-sm'>
      <div className='capsule'>
             <div className='row'>
              <div className='col-sm-4'>
                  <div className='cap-image'>
                      
               <img className="capimg" alt="Group" src={patient2} />
                            
                  </div>
              </div>
              <div className='col-sm-8'>
                  <div className='row'>
                      <h6>Dr.John</h6>
                  </div>
                  
                  <div className='row'>
               
                  <p ><NavLink to="/administrator/viewhealthproviderinfo" > &nbsp;View User Information</NavLink></p>

                  </div>

              </div>
           
          </div>
      </div>
      </div>
      </div>
                                        </div>
                                     
                                      </div>
                                  </div>

                              </div>
                                    </div>

                          
                              
                            </div>
                            <div className='col-sm-4'>

                                <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                            
                                        <form className="health-form"  onSubmit={handleSubmit(fetchUser)}  style={{height: 'max-content'}} name='login-form'>

          <h2>Search Health Providers</h2><br />

          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Search</button></p>
         
         {/* <p className='newAcc'>Or</p> */}
         {/* <p className='newAcc'> <button className="health-btn" onClick={handleClick}>Add New User</button></p> */}
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>

                                        </div>
                                    </div>

                                </div>

                        </div>
               
                   

                </div>
                </div>
        
                
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
            </div>
        </div>


    </div>

);

}
export default AdminHealthProviders;
