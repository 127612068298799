import {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from './components';
import {routes} from '../utils/routes'
import './StaticHome/css/style.css';
import logImage from '../assets/signpic.png';
import { create_user } from '../api/auth';

const SignUpPage = () => {
  const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
  const [serverError, setServerError] =useState(null);
  const [serverSuccess, setServerSuccess] =useState(null);
  const [submitting, setSubmitting] =useState(false);
  const navigate =useNavigate();

  const fields =[
    [{fieldName: 'firstname', label: 'First name', validations: {
      required: "First name Required*"
    }},
    {fieldName: 'lastname', label: 'Last Name', validations: {
      required: "Last name Required*"
    }}],

    [{fieldName: 'email', label: 'Email Address', type: 'email', validations: {
      required: "Email Required*",
      isEmail: "Provide a valid email address",
    }},
    {fieldName: 'password', label: 'Password', type: 'password', validations: {
      required: "Password Required*"
    }},
    {fieldName: 'cpassword', label: 'Confirm Password', type: 'password', validations: {
      required: "Confirm Password*",
      validate: val => val !=watch('password')? 'Passwords do not match': null,
    }}
   ],

   [ 
    {fieldName: 'dob', label: 'Date of Birth', validations: {
      required: "Provide your date of birth*"
    }, type: 'date'},
    {fieldName: 'phone', label: 'Phone', type: 'tel', placeholder: '123-45-678', validations: {
      required: "Phone number Required*",
      pattern: {
        value: /^[0-9-]+$/,
        message: 'Invalid phone number'
      }
    }}
],
 
    [
      {fieldName: 'gender', label: 'Gender', type: 'select', validations: {
        required: "Gender Required*"
      }, options: [
        {value: '', label: 'Select', disabled: true, selected: true},
        {value: 'male', label: 'Male'},
        {value: 'female', label: 'Female'},
        {value: 'bi', label: 'Bi'},
        {value: 'private', label: 'Don\'t want to disclose'},
      ]},
      {fieldName: 'role', label: 'Role', type: 'select', validations: {
      required: "Specify your role*"
    }, options: [
      // {value: '', label: 'Select', disabled: true, selected: true},
      {value: '1', label: 'Patients', selected: true},
      // {value: '2', label: 'Healthcare Providers'},
      // {value: '3', label: 'Administrator'},
      // {value: '4', label: 'Health Administrator'},
      // {value: '5', label: 'Pharmacist'},
    ]}],
    [{fieldName: 'address1', label: 'Address', validations: {
      required: "Address Required*"
    }},
    {fieldName: 'city', label: 'City', validations: {
      required: "City Required*"
    }},
    {fieldName: 'state', label: 'State', validations: {
      required: "State Required*"
    }},
    {fieldName: 'postalcode', label: 'Postal Code', type: 'number',validations: {
      required: "Postal Code Required*"
    }},
    {fieldName: 'country', label: 'Country', validations: {
      required: "Country Required*"
    }},
    {fieldName: 'emcname', label: 'Emergency Contact Name', validations: {
      required: "Emergency Contact Name Required*"
    }},
    {fieldName: 'emcnumber', label: 'Emergency Contact Number',type: 'tel', validations: {
      required: "Emergency Contact Number Required*"
    }}
  ],
  ]

  const handleSignup = async values => {
    setServerError(null);
    setServerSuccess(null);
    setSubmitting(true);
    const form = document.forms['signup-form'];
    try {
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled = true);
        delete values.cpassword;
        console.log(values);
        const { status, message, error } = await create_user(values);
        console.log(error);
        if (status == 201) {
          setServerSuccess(message)
        };
        if (status >= 400) throw new Error(message);        
        else {
            reset();           
            setTimeout(function() {
              navigate('/login', { replace: true });
          }, 5000);
        }
    } catch (error) {
        if (error.response) setServerError(error.response.data.message);
        else setServerError(error.message);
    } finally {
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled = false);
    }
}

  return (
      <div className="dash">
        <section className="text">
      <div className="container common-container">
    <div className='row'>
        <div className="col-sm">
          <div className='loginform-block'>
        <form className="login-form" onSubmit={handleSubmit(handleSignup)} style={{height: 'max-content'}} name='signup-form'>
          <h2>SignUp</h2>  {serverError &&<div className="error-alert">{serverError}</div>}
          {serverSuccess &&<div className="success-alert">{serverSuccess}</div>}
          {fields.map((row, index) =><div key={index} className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Create</button></p>        
           <p className='newAcc'>Already have an account? <NavLink to="/login" > &nbsp;Login</NavLink></p></>)}
        
         
          </div>
        </form>
        </div>
        </div>
 
        <div className="col-sm">    
        <div className='signhero-block'>
      <img
          src={logImage}
          alt="illustration"
          className="signhero-image"
        />
      </div>
      </div>  
      </div>
      </div>
    </section>

      
        <br/><br/><br/><br/><br/><br/>
      </div>
  )
}

export default SignUpPage;