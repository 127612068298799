import { useEffect } from 'react';
import {routes} from '../../utils/routes';
import {Outlet, useNavigate} from 'react-router-dom';
import {FooterComponent, NavigationBarComponent} from '../components';

const PatientLayoutPage = () => {
  const navigate =useNavigate();
  const links =[
    {to: '/patient', label: 'Home'},
    {to: '/patient/phr', label: 'Health Records'},
    {to: '/patient/community', label: 'Community'},
    {to: '/patient/prescriptions', label: 'Prescriptions'},
    {to: '/patient/chat', label: 'Chat'},
    {to: '/logout', label: 'Logout'},
  ]

  useEffect(() =>{
    const {isLoggedIn, role} =JSON.parse(localStorage.getItem('session')) || {};
    if(!isLoggedIn) navigate('/login', {replace: true});
    if(role !='1') navigate(routes[role]|| '/logout', {replace: true});
  },[]);
  return (
    <>
    <NavigationBarComponent links={links} heading={''}/>
    <Outlet />
    <FooterComponent />
    </>
  )
}

export default PatientLayoutPage;