import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';
import '../patient/assets/style.css';
import '../healthcareprovider/assets/style.css';
import heartImg from '../../assets/heart.png';
import sugarImg from '../../assets/sugar.png';
import armImg from '../../assets/arm.png';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import patient2 from '../../assets/patient2.jpg'
import patient1 from '../../assets/patient1.jpg'
import calendarImg from '../../assets/calendarillustration.png'
import surgeryImg from '../../assets/surgeryillustration.png'
import callImg from '../../assets/callillustration.png'
import {NavLink} from 'react-router-dom';

const AdminHealthProviderInfo = () => {
 
    const [symptoms, setSymptoms] = useState([]);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/patient/phr/healthupdate', { replace: true });
    };

    const handleSymptomChange = (e) => {
        const { value } = e.target;
        setSymptoms(value.split(',').map(symptom => symptom.trim()));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle symptom assessment here, possibly by sending the symptoms to a backend API
        console.log(symptoms);
    };

    return (
        <div className="healthcare-dashboard">


            <div className="desktop-wrapper">

                <div className="desktop">
                    <div className='community-content'>
                        <div className="community-head">User Information</div>

                        <div className='row'>
                            <div className='col-sm-8'>

                                <div className='patient-leftblock'>
                                    <div className='row'>
                                    <div className='capsule2'>
             <div className='row'>
              <div className='col-sm-2'>
                  <div className='healthprovider-image'>
                      
               <img className="capimg" alt="Group" src={patient2} />
                            
                  </div>
              </div>
              <div className='col-sm-8'>
                  <div className='row'>
                      <h5>User Name : Dr.Joseph</h5>
                  </div>
                  
                  <div className='row'>
                  <h6>User Id : PA00001</h6>
                  <h6>User Type : Health Provider</h6>
                  <h6>Medical License Id : MLI00001</h6>
                  <h6>Joined At : 12-March-2024 12:00 AM</h6>               

                  </div>
                 
                  
              </div>
              <div className='col-sm'>
            
                    <p ><NavLink to="/administrator/viewhealthproviderinfo" > &nbsp;Disable Account</NavLink></p>

                    <p ><NavLink to="/administrator/updateuserinfo" > &nbsp;Update Account</NavLink></p>
                  </div>
          </div>
      </div>
                                    </div>
                                    <div className='row'>

                                    <div className=''>
                                <div className='healthproviderdash-left'>
                                    <div className='row'>
                                        <div className='col-sm'>
            <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={calendarImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>Appointments</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>1,250</h6>

                  </div>

                </div>
             
            </div>
            </div>
              

                                        </div>
                                        <div className='col-sm'>
                                        <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={surgeryImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>Surgeries</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>60</h6>

                  </div>

                </div>
             
            </div>
            </div>
                                            </div>
                                            <div className='col-sm'>
                                            <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={callImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h5 className='newAcc'>Call Consultancy</h5>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>90</h6>

                  </div>

                </div>
             
            </div>
            </div>  
                                            </div>

                                    </div>
                                    <div className='row'>
                                    <div className='healthforum-add'>
                                  
                                  <div className='row'>
                                      <div className='add-healthforum'>
                                        <div className="health-form2">
                                        <h3 className='newAcc'>Today's Appointments</h3><br />
                                        <div className='row'>
                                        <div className='col-sm'>
                                        <div className='capsule'>
             <div className='row'>
              <div className='col-sm-4'>
                  <div className='cap-image'>
                      
               <img className="capimg" alt="Group" src={patient1} />
                            
                  </div>
              </div>
              <div className='col-sm-8'>
                  <div className='row'>
                      <h6>Joseph</h6>
                  </div>
                  
                  <div className='row'>
                  <p>Appoinment At : 11:00 AM</p>
                  <p className='newAcc'><NavLink to="/administrator/patientappointmentview" > &nbsp;View Appointment</NavLink></p>

                  </div>

              </div>
           
          </div>
      </div>
      </div>
      <div className='col-sm'>
      <div className='capsule'>
             <div className='row'>
              <div className='col-sm-4'>
                  <div className='cap-image'>
                      
               <img className="capimg" alt="Group" src={patient2} />
                            
                  </div>
              </div>
              <div className='col-sm-8'>
                  <div className='row'>
                      <h6>Joseph</h6>
                  </div>
                  
                  <div className='row'>
                  <p>Appoinment At : 12:00 AM</p>
                  <p className='newAcc'><NavLink to="/administrator/patientappointmentview" > &nbsp;View Appointment</NavLink></p>

                  </div>

              </div>
           
          </div>
      </div>
      </div>
      </div>
                                        </div>
                                     
                                      </div>
                                  </div>

                              </div>
                                    </div>

                                </div>
                                
                            
                              
                            </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm'>
                                        <div className='card-prop'>
                                                <div className='row'>
                                                    <div className='col-sm'>
                                                    <img className="capimg" alt="Group" src={sugarImg} />
                                                    </div>
                                                    <div className='col-sm'>
                                                    <h5>Blood Sugar</h5>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="text-value"><h3 className='arc'>80</h3> &nbsp;<a>mg / dL </a></div>                                                    
                                                </div>
                                                <div className='row'>
                                                    <div><h6 className="text-status">Normal</h6></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-sm'>
                                        <div className='card-prop'>
                                                <div className='row'>
                                                    <div className='col-sm'>
                                                    <img className="capimg" alt="Group" src={heartImg} />
                                                    </div>
                                                    <div className='col-sm'>
                                                    <h5>Heart Rate</h5>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="text-value"><h3 className='arc'>98</h3> &nbsp;<a>bpm </a></div>                                                    
                                                </div>
                                                <div className='row'>
                                                    <div><h6 className="text-status">Normal</h6></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm'>
                                        <div className='card-prop'>
                                                <div className='row'>
                                                    <div className='col-sm'>
                                                    <img className="capimg" alt="Group" src={armImg} />
                                                    </div>
                                                    <div className='col-sm'>
                                                    <h5>Blood Pressure</h5>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="text-value"><h3 className='arc'>102</h3> &nbsp;<a>/ 72 mmhg </a></div>                                                    
                                                </div>
                                                <div className='row'>
                                                    <div><h6 className="text-status">Normal</h6></div>
                                                </div>
                                            </div>
                                        </div>
    

                                    </div>
                                    <br/> <br/><br/>
                                 

                                </div>

                            </div>
                            <div className='col-sm-4'>
                                <div className='patient-rightblock'>
                                    <h4 className='newAcc'>BMI Information</h4>
                                    <br />
                                    <center>
                                        <div className='row'>
                                            <div className='col-sm'>
                                                <div className="overlap-4">
                                                    <div className="text-wrapper-15">Weight</div>
                                                    <div className="text-wrapper-17">72 kg</div>
                                                    <div className="group-3">
                                                        <div className="rectangle-3" />
                                                        <div className="rectangle-4" />
                                                        <div className="rectangle-5" />
                                                        <div className="rectangle-6" />
                                                        <div className="rectangle-7" />
                                                        <div className="rectangle-8" />
                                                        <div className="rectangle-9" />
                                                        <div className="rectangle-10" />
                                                        <div className="rectangle-11" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm'>
                                                <div className="overlap-3">
                                                    <div className="text-wrapper-15">Height</div>
                                                    <div className="text-wrapper-16">170 cm</div>
                                                    <div className="group-3">
                                                        <div className="rectangle-3" />
                                                        <div className="rectangle-4" />
                                                        <div className="rectangle-5" />
                                                        <div className="rectangle-6" />
                                                        <div className="rectangle-7" />
                                                        <div className="rectangle-8" />
                                                        <div className="rectangle-9" />
                                                        <div className="rectangle-10" />
                                                        <div className="rectangle-11" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </center>
                                    <br />

                                    <div className='row'>
                                        <center>
                                            <div className="overlap-group-2">

                                                <div className="text-wrapper-7">24.9</div>
                                                <div className="text-wrapper-8">Body Mass Index (BMI)</div>
                                                <div className="div-wrapper">
                                                    <div className="text-wrapper-9">You’re Healthy</div>
                                                </div>
                                                <div className="group-2">
                                                    <div className="text-wrapper-10">15</div>
                                                    <div className="text-wrapper-11">18.5</div>
                                                    <div className="text-wrapper-12">25</div>
                                                    <div className="text-wrapper-13">30</div>
                                                    <div className="text-wrapper-14">40</div>
                                                    <div className="rectangle" />
                                                    <div className="rectangle-2" />
                                                </div>

                                            </div>
                                        </center>
                                    </div>

                                </div>

                            </div>
                        </div>  </div>  </div>
            </div>  </div>
    );

}

export default AdminHealthProviderInfo;