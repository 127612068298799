import { api } from ".";


export const login_user = async (credentials) => {
  
        const response = await api.post('/login', credentials);
        console.log(response);
        const { status, data: { message, id, role, username } } = response;
        return { status, message, id, role, username };
    
}

export const create_user = async user_data =>{
    console.log(user_data);
        const response = await api.post('/signup', user_data);
    console.log(response);
    const {status, data: {message, error}} = response;
    console.log(error);
    return {status, message, error}
}