
import '../commonstyle.css';
import '../patient/assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import React, { useEffect, useState } from 'react';
import { create_post , fetch_topposts} from '../../api/common';
function HealthProviderCommunity() {

    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const [topPosts, setTopPosts] = useState([]);
    const storedSessionDataString = localStorage.getItem('session');
    const storedSessionData = JSON.parse(storedSessionDataString);
    const currentUser = storedSessionData.id;
    console.log(currentUser);
    useEffect(() => {
        const fetchTopPosts = async () => {
            try {
                const  fetchedPosts  = await fetch_topposts();
                // Handle fetched posts
                console.log(fetchedPosts);
               setTopPosts(fetchedPosts);
            } catch (error) {
                console.error('Error fetching top posts:', error);
                // Handle error
            }
        };
    
        fetchTopPosts();
    });
    


    const createPost =async values =>{
        setServerError(null);
        setSubmitting(true);
        const form =document.forms['post-form'];
        try {
          [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
            console.log(values);
            values['userid'] = currentUser
          const {status, message} =await create_post(values);
    
          if(status >=400) throw new Error(message);
          else {
            reset();
                try {
                    const  fetchedPosts  = await fetch_topposts();
                    // Handle fetched posts
                    console.log(fetchedPosts);
                   setTopPosts(fetchedPosts);
                } catch (error) {
                    console.error('Error fetching top posts:', error);
                }
          }
        } catch (error) {
          if(error.response) setServerError(error.response.data.message);
          else setServerError(error.message);
        }
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
      }
      const fields =[
        [       
          
        {fieldName: 'category', label: 'Category', type: 'select', validations: {
          required: "Specify your Category*"
        }, options: [
          {value: '', label: 'Select', disabled: true, selected: true},
          {value: '1', label: 'Nutrition & Dieting'},
          {value: '2', label: 'Physical Activities'},
          {value: '3', label: 'Injuries, Fractures and Burns'},
        ]},
        {fieldName: 'posttext', label: 'Description', type :'textarea', validations: {
          required: "Description Required*"
        }},
  
        ],
    
        
      ]
  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">Health Forums</div>
                
            <div className='row'>
                            <div className='col-sm-8'>
                                
                                <div className='view-threads'>
                                {topPosts ? topPosts.map((post) => (
                                    <div> 
                <div key={post.PostID} className="thread">
                    <div className="row">
                        <div className="row">
                            <div className="col-sm thread-head">
                                <h4>{post.CategoryName}</h4>
                            </div>
                            {/* <div className="col-sm thread-count">
                                <p>{post.topicCount} Topics</p>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="thread-desc">
                                <p>{post.PostContent}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="thread-by">
                                <p>Latest post by {post.displayname} {post.PostDate}</p>
                            </div>
                        </div>
                    </div>
     
                    </div>
                              
                                    <p className="communityline" > </p>
                </div>
                
            )) : <div></div>}
      
                                
                                  
                                    
                                </div>
                          
                              
                            </div>
                            <div className='col-sm-4'>

                                <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                        <form className="health-form"  onSubmit={handleSubmit(createPost)}  style={{height: 'max-content'}} name='post-form'>
          <h2>Write your new topic now</h2><br />
          <div className='row'>
                                    <img className="" alt="Group" src={healthImg} />
                                    </div>
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Start New Topic</button></p>
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>

                                        </div>
                                    </div>

                                </div>

                        </div>
               
                   

                </div>
                </div>
        
                

            </div>
        </div>


    </div>

);

}
export default HealthProviderCommunity;
