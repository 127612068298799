import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';
import './assets/style.css';
import heartImg from '../../assets/heart.png';
import sugarImg from '../../assets/sugar.png';
import armImg from '../../assets/arm.png';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetch_phmbyid} from '../../api/common';
const PatientDashboard = () => {
    const storedSessionDataString = localStorage.getItem('session');
    const storedSessionData = JSON.parse(storedSessionDataString);
    const currentUser = storedSessionData.id;
    console.log(currentUser);

    const [symptoms, setSymptoms] = useState([]);
    const navigate = useNavigate();
    const [healthProfile, setHealthProfile] = useState(null);
    const handleClick = () => {
        navigate('/patient/phr/healthupdate', {replace: true});
    };

    

    useEffect(() => {
        const fetchPHR = async() => {
            try {
                const  {status, data}  = await fetch_phmbyid(currentUser);
                // Handle fetched posts
                if(status >=400) throw new Error(message);
          else {
                console.log('map:',data);
             setHealthProfile(data);
          }
            } catch (error) {
                console.error('Error fetching phr:', error);
                // Handle error
            }
        };
    
        fetchPHR();
    }, []);

  
    return (
        <div className="healthcare-dashboard">
      

            <div className="desktop-wrapper">
            
                <div className="desktop">
                  
                   
                    <div className="overlap">
                   
                        <div className="text-wrapper">Health Profile</div>
                        
                        <div className="overlap-group">
                            <div className="BMI">
                                <div className="overlap-2">
{/*                     
                        
                                    <div className="group">
                                        <div className="overlap-group-2">
                                            <div className="text-wrapper-7">24.9</div>
                                            <div className="text-wrapper-8">Body Mass Index (BMI)</div>
                                            <div className="div-wrapper">
                                                <div className="text-wrapper-9">You’re Healthy</div>
                                            </div>
                                            <div className="group-2">
                                                <div className="text-wrapper-10">15</div>
                                                <div className="text-wrapper-11">18.5</div>
                                                <div className="text-wrapper-12">25</div>
                                                <div className="text-wrapper-13">30</div>
                                                <div className="text-wrapper-14">40</div>
                                                <div className="rectangle" />
                                                <div className="rectangle-2" />
                                            </div>
                                        </div>
                                    </div> */}
                                    
                                    <div className="overlap-wrapper">

                                        <div className="overlap-3">

                                            <div className="text-wrapper-15">Height</div>
                                            <div className="text-wrapper-16">{healthProfile ? (healthProfile.height) : 0.00} cm</div>
                                            <div className="group-3">
                                                <div className="rectangle-3" />
                                                <div className="rectangle-4" />
                                                <div className="rectangle-5" />
                                                <div className="rectangle-6" />
                                                <div className="rectangle-7" />
                                                <div className="rectangle-8" />
                                                <div className="rectangle-9" />
                                                <div className="rectangle-10" />
                                                <div className="rectangle-11" />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="overlap-group-wrapper">
                                        
                                        <div className="overlap-4">
                                            <div className="text-wrapper-15">Weight</div>
                                            <div className="text-wrapper-17">{healthProfile ? (healthProfile.weight) : 0.00}  kg</div>
                                            <div className="group-3">
                                                <div className="rectangle-3" />
                                                <div className="rectangle-4" />
                                                <div className="rectangle-5" />
                                                <div className="rectangle-6" />
                                                <div className="rectangle-7" />
                                                <div className="rectangle-8" />
                                                <div className="rectangle-9" />
                                                <div className="rectangle-10" />
                                                <div className="rectangle-11" />
                                            </div>
                                        </div>
                                        <br/>
                                        <button className="health-btn" onClick={handleClick} >Update with Latest Readings</button>

                                    </div>
                                    
                                    <div className="text-wrapper-18">BMI Calculator</div>
                                    {/* <div className="">
                                        {/* <div className="text-wrapper-20">Update</div> */}
                                        {/* <img className="frame-7" alt="Frame" src="frame-3.svg" /> 
                                    </div>*/}
                                    <img className="line" alt="Line" src="line-4.svg" />
                                </div>
                            </div>
                          
                            {/* <button className="health-btn custframe-6" >Update</button> */}
                         

                        </div>
                        
                        <div className="blood-sugar">
                            <div className="overlap-5">
                                <div className="text-wrapper-51">Blood Sugar</div>
                                <div className="text-wrapper-52">mg / dL</div>
                                <div className="text-wrapper-22">{healthProfile ? (healthProfile.blood_sugar) : 0}</div>
                                {/* <img className="group-8" alt="Group" src="group-11.png" /> */}
                                <div className="group-wrapper">
                                    <img className="group-9" alt="Group" src={sugarImg} />
                                </div>
                                <div className="frame-11">
                                    <div className="text-wrapper-25">Normal</div>
                                </div>
                            </div>
                        </div>
                        <div className="blood-pressure">
                            <div className="overlap-5">
                                <div className="text-wrapper-21">Blood Pressure</div>
                                <div className="text-wrapper-22">{healthProfile ? (healthProfile.blood_pressure) : 0}</div>
                                <div className="text-wrapper-23">/ 72</div>
                                <div className="text-wrapper-24">mmhg</div>
                                {/* <img className="group-4" alt="Group" src="group-30.png" /> */}
                                <div className="noun-blood-pressure-wrapper">
                                    <img
                                        className="noun-blood-pressure"
                                        alt="Noun blood pressure"
                                        src={armImg}
                                    />
                                </div>
                                <div className="frame-8">
                                    <div className="text-wrapper-25">Normal</div>
                                </div>
                            </div>
                        </div>

                        <div className="heart-rate">
                            <div className="overlap-5">
                                <div className="text-wrapper-51">Heart Rate</div>
                                <div className="text-wrapper-22">{healthProfile ? (healthProfile.heart_rate) : 0}</div>
                                <div className="text-wrapper-52">bpm</div>
                                <div className="img-wrapper">
                                    <img className="group-10" alt="Group" src={heartImg} />
                                </div>
                                <div className="frame-13">
                                    <div className="text-wrapper-25">Normal</div>
                                </div>
                                {/* <img className="group-11" alt="Group" src="group-29.png" /> */}
                            </div>
                        </div>
   
                    </div>
                    

                </div>
            </div>


        </div>

    );

}

export default PatientDashboard;