import React, { useEffect, useState } from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import './assets/style.css';
import calendarImg from '../../assets/calendarillustration.png'
import surgeryImg from '../../assets/surgeryillustration.png'
import callImg from '../../assets/callillustration.png'
import patient1 from '../../assets/patient1.jpg'
import patient2 from '../../assets/patient2.jpg'
import {useForm} from 'react-hook-form';
import {NavLink, useNavigate} from 'react-router-dom';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import {gethealthproviderappointments } from '../../api/admin';


function HealthProviderDashboard() {
    const storedSessionDataString = localStorage.getItem('session');
    const storedSessionData = JSON.parse(storedSessionDataString);
    const currentUser = storedSessionData.id;
    console.log(currentUser);
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [appoints, setAppoints] =useState(null);
    const [totalAppoints, setTotalAppoints] =useState(null);
    const [submitting, setSubmitting] =useState(false);

    const formatDate =timestamp =>{
        const new_date =new Date(timestamp);
        const format_2dgt =v =>parseInt(v) <10? `0${v}`: v;
        const days =['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const year =new_date.getFullYear();
        const month =format_2dgt(new_date.getMonth()+1);
        const day =new_date.getDay()-1;
        const date =format_2dgt(new_date.getDate());
        return `${days[day]}, ${date}-${month}-${year}`;
    }

    const updatehandleClick = () => {
        navigate('/healthprovider/patientappointmentupdate', {replace: true});

    };
    const fields =[
      [       
        
        {fieldName: 'apd', label: 'Select Date',  validations: {
            required: "Date Required*"
          }, type: 'date'},
      ],

  
      
    ]

    useEffect(() => {
        const getAppointments = async () => {
            try {
                const data  = await gethealthproviderappointments(currentUser);
    
             
                    console.log('data', data)
                    setAppoints(data);
                    setTotalAppoints(data.length)
            } catch (error) {
                console.error('Error fetching appointments:', error);
            }
        };
    
        if(!appoints){
        getAppointments();
        }
    }); 
    

    const UserComponent = ({ appointment }) => (
        <div className='col-sm'>
          <div className='capsule'>
            <div className='row'>
              <div className='col-sm-4'>
                <div className='cap-image'>
                  <img className="capimg" alt="Group" src={patient1} />
                </div>
              </div>
              <div className='col-sm-8'>
                <div className='row'>
                  <h6>{appointment && appointment.DisplayName ? appointment.DisplayName : ''}</h6>
                </div>
                <div className='row'>
                  <h6>{appointment && appointment.DateTime ? formatDate(appointment.DateTime) : ''}</h6>
                </div>
                <div className='row'>
                  <p>{appointment && appointment.UserID ? <NavLink to={`/healthprovider/viewpatientinfo/${appointment.UserID}`}>View User Information</NavLink> : ''}</p>
                  <p>{appointment && appointment.UserID ? <NavLink to={`/healthprovider/patientappointmentupdate/${appointment.AppointmentID}`}>Update Appointement</NavLink> : ''}</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      );
      
    
  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">Overview</div>
                
            <div className='row'>
                            <div className='col-sm-13'>
                                <div className='healthproviderdash-left'>
                                    <div className='row'>
                                        <div className='col-sm'>
            <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={calendarImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>Appointments</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>{totalAppoints ? totalAppoints : 0}</h6>

                  </div>

                </div>
             
            </div>
            </div>
              

                                        </div>
                                        <div className='col-sm'>
                                        <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={surgeryImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h4 className='newAcc'>Surgeries</h4>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>0</h6>

                  </div>

                </div>
             
            </div>
            </div>
                                            </div>
                                            <div className='col-sm'>
                                            <div className='capsule1'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='healthprovider-image'>
                        
                 <img className="capimg" alt="Group" src={callImg} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row '>
                      <h5 className='newAcc'>Call Consultancy</h5>
                  </div>
                  
                  <div className='row'>
                  <h6 className='newAcc'>0</h6>

                  </div>

                </div>
             
            </div>
            </div>  
                                            </div>

                                    </div>
                                    <div className='row'>
                                    <div className='healthforum-add'>
                                  
                                  <div className='row'>
                                      <div className='add-healthforum'>
                                        <div className="health-form2">
                                        <h3 className='newAcc'>Upcoming Appointments</h3><br />
                                        <div className='row'>
  {appoints ? (
    appoints
    .filter(appointment => appointment.StatusID === 1)
    .map(appointment => (
        console.log(appointment),
      <UserComponent key={appointment.AppointmentID} appointment={appointment} />
    ))
  ) : (
    <div>No appointments found</div>
  )}

</div>
                                        </div>
                                     
                                      </div>
                                  </div>

                              </div>
                                    </div>

                                </div>
                                
                            
                              
                            </div>
                            {/* <div className='col-sm-4'>

                           


                                <div className='healthforum-add'>
                                  
                                    <div className='row'>
                                        <div className='add-healthforum'>
                                        <form className="health-form"  onSubmit={handleSubmit(handleSignup)}  style={{height: 'max-content'}} name='login-form'>
          <h2>Calendar</h2><br />
         
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}

          </div>)} 
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Search</button></p>
         <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
       
        <div className='row'>
    <div className='medications'>
        <h2>Result </h2>
     
            <div className='capsule'>
               <div className='row'>
                <div className='col-sm-4'>
                    <div className='cap-image'>
                        
                 <img className="capimg" alt="Group" src={patient2} />
                              
                    </div>
                </div>
                <div className='col-sm-8'>
                <div className='row'>
                      <h6>Joseph</h6>
                  </div>
                  
                  <div className='row'>
                  <p>Appoinment At : 11:00 AM</p>
                  <p className='newAcc'><NavLink to="/healthprovider/patientappointmentview" > &nbsp;View Appointment</NavLink></p>

                  </div>

                </div>
             
            </div>
        </div>


    </div>

</div>
        </form>

                                        </div>
                                    </div>

                                </div>


                        </div> */}
               
                   

                </div>
                </div>
        
                

            </div>
        </div>


    </div>

);

}
export default HealthProviderDashboard;
