import { NavLink } from 'react-router-dom';
import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';
import '../patient/assets/style.css';
import heartImg from '../../assets/heart.png';
import sugarImg from '../../assets/sugar.png';
import armImg from '../../assets/arm.png';
import React, { useState } from 'react';
import {useForm} from 'react-hook-form';

import { FormInputComponent, LoadingAnimationComponent } from '../components';

const AdminSystemConfiguration = () => {
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const fields =[
      [       
        {fieldName: 'dob', id :'dob' , label: 'System Date', disabled : true, validations: {
          required: "System Date"
        },  type: 'date'},
      ]
      
    ]

      const handleSignup =async values =>{
        setServerError(null);
        setSubmitting(true);
        const form =document.forms['signup-form'];
        try {
          [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
          delete values.cpassword;
          const {status, message} =await create_user(values);
    
          if(status >=400) throw new Error(message);
          else {
            reset();
            navigate('/login', {replace: true});
          }
        } catch (error) {
          if(error.response) setServerError(error.response.data.message);
          else setServerError(error.message);
        }
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
      }
    
    return (
        <div className="section">
        <section className="hero">
      <div className="">
    
        <div className="logcolumn">
        <form className="login-form"  onSubmit={handleSubmit(handleSignup)}  style={{height: 'max-content'}} name='login-form'>
          <h2>Settings</h2><br />
          {fields.map((row, index) =><div key={index}  className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder, disabled}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder} disabled={disabled} />))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit" disabled>Update</button></p>
 
            <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>
        </div>
 
       
      </div>
    </section>

      
        <br/><br/><br/><br/><br/><br/>
      </div>
       

    );

}

export default AdminSystemConfiguration;