import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://smart.health.hub.axk8159.uta.cloud/public/api',
    headers: {
        'Content-Type': 'application/json',
    },
});





