import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        navigate('/', { replace: true }); // Redirect to the home page after clearing localStorage
    }, []); // Dependency array is empty, so the effect runs only once when the component mounts

    // Render null because there's no need to render anything for a logout page
    return null;
}

export default LogoutPage;
