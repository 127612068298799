import {NavLink} from 'react-router-dom';
import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';

const HomePage = () => {
  return (
    <section className="hero">
      <div className="container common-container">
        <div className='row'>
        <div className="col-sm homepage-leftblock">
          <h1> Virtual Healthcare for you</h1>
          <p style={{ textAlign: 'justify', color: 'grey' }}>
           Your one-stop solution for managing your healthcare needs
          </p>
          <NavLink to="/login" className="consult-btn">
            <button className="health-btn">Consult Today</button>
          </NavLink>
        </div>
        <div className="col-sm">
          <img
            src={HeroImage}
            alt="illustration"
            className="hero-image"
          />
        </div>
        </div>
      </div>
    </section>
  )
}

export default HomePage;