import React, { useState } from 'react';
import '../commonstyle.css';
import '../patient/assets/style.css';
import healthImg from '../../assets/healthillustration.webp'
import patient1 from '../../assets/patient1.jpg'
import patient2 from '../../assets/patient2.jpg'
import {useForm} from 'react-hook-form';
import {NavLink, useNavigate} from 'react-router-dom';
import { FormInputComponent, LoadingAnimationComponent } from '../components';

function HealthProviderAppointmentView() {
    const navigate = useNavigate();
    const [appoints, setAppoints] = useState([]);
    const { id } = useParams();

    const updatehandleClick = () => {
        navigate('/healthprovider/patientappointmentupdate', {replace: true});

    };
    const viewpatienthandleClick = () => {
<NavLink to={`/healthprovider/viewpatientinfo/${id}`}></NavLink>
    };
    
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const fields =[
      [       
        
        {fieldName: 'apd', label: 'Select Date',  validations: {
            required: "Date Required*"
          }, type: 'date'},
      ],
  
      
    ]

    useEffect(() => {
        const fetchAppoints = async () => {
          try {
            const { status, data } = await fetchAppointsbyappid(id);
            console.log('data', data);
            // Handle fetched appointments
            if (status >= 400) {
              throw new Error(message);
            } else {
            
                    setAppoints(appointment);
         
            
            }
          } catch (error) {
            console.error('Error fetching Appointments:', error);
            // Handle error
          }
        };
      
        fetchAppoints();
      
      }, [id]);

  return (
    <div className="healthcare-dashboard">
  

        <div className="desktop-wrapper">
        
            <div className="desktop">
                <div className='community-content'>
                <div className="community-head">Appointment Details</div>
                
            <div className='row'>
                            <div className='col-sm-8'>
                                
                            <div className='capsule'>
             <div className='row'>
              <div className='col-sm-2'>
                  <div className='healthprovider-image'>
                      
               <img className="capimg" alt="Group" src={patient2} />
                            
                  </div>
              </div>
              <div className='col-sm-8'>
                  <div className='row'>
                      <h5>Patient Name : Joseph</h5>
                  </div>
                  
                  <div className='row'>
                  <h6>Appoinment At : 12-March-2024 12:00 AM</h6>
                  <h6>Appoinment Status : In Progress</h6>

                  </div>

              </div>
           
          </div>
      </div>
                              
                            </div>
                            <div className='col-sm-4'>

                            <div className='healthforum-add'>
                                  
                                  <div className='row'>
                                      <div className='add-healthforum'>
                                        <div className="health-form">
                                            <div className='manage-btns'>

                                           
                                        <button onClick={updatehandleClick} className='health-btn'>Update Appointement</button>
                                        <br/>         <br/>
                                        <button onClick={viewpatienthandleClick}  className='health-btn'>Patient Health Records</button>
                                        </div>
                                        </div>
                                     
                                      </div>
                                  </div>

                              </div>


                               


                        </div>
               
                   

                </div>
                </div>
        <br/>     <br/>     <br/>     <br/>     <br/>     <br/>     <br/>
        <br/>     <br/>     <br/>     <br/>     <br/>     <br/>     <br/>

            </div>
        </div>


    </div>

);

}
export default HealthProviderAppointmentView;
