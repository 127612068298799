import React from 'react'

const LoadingAnimationComponent = ({message}) => {
  return (
    <div className='loader'>
        <div className="spinner"></div>
        <p className="animation-label">{message || 'Please Wait...'}</p>
    </div>
  )
}

export default LoadingAnimationComponent;