
import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';
import './assets/style.css';
import heartImg from '../../assets/heart.png';
import sugarImg from '../../assets/sugar.png';
import armImg from '../../assets/arm.png';
import React, { useEffect, useState } from 'react';
import {useForm} from 'react-hook-form';
import { fetch_phmbyid, createorupdatephr} from '../../api/common';
import {NavLink, useNavigate} from 'react-router-dom';
import { FormInputComponent, LoadingAnimationComponent } from '../components';

const PatientHealthUpdate = () => {
    const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
    const [serverError, setServerError] =useState(null);
    const [submitting, setSubmitting] =useState(false);
    const storedSessionDataString = localStorage.getItem('session');
    const storedSessionData = JSON.parse(storedSessionDataString);
    const currentUser = storedSessionData.id;
    console.log(currentUser);
    const [healthProfile, setHealthProfile] = useState(null);
    const navigate =useNavigate();
   

    useEffect(() => {
      const fetchPHR = async() => {
          try {
              const  {status, data}  = await fetch_phmbyid(currentUser);
              // Handle fetched posts
              if(status >=400) throw new Error(message);
        else {
              console.log('map:',data);
           setHealthProfile(data);
        }
          } catch (error) {
              console.error('Error fetching phr:', error);
              // Handle error
          }
      };
  
      fetchPHR();
  }, []);

    const fields =[
        [{fieldName: 'blood_pressure', label: 'Blood Pressure', validations: {
          required: "Blood Pressure Required*"
        }},
        {fieldName: 'heart_rate', label: 'Heart Rate', validations: {
          required: "Heart Rate Required*"
        }},
    
        {fieldName: 'blood_sugar', label: 'Blood Sugar', validations: {
            required: "Blood Sugar Required*"
          }},
        
          {fieldName: 'height', label: 'Height', validations: {
            required: "Height Required*"
          }},
          {fieldName: 'weight', label: 'Weight', validations: {
            required: "Weight Required*"
          }}
        ],
    
        
      ]
      const handlePHRUpdate =async values =>{
        setServerError(null);
        setSubmitting(true);
        const form =document.forms['phr-form'];
        try {
          [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
          values['userid'] = currentUser
          const {status, message} =await createorupdatephr(values);
    
          if(status >=400) throw new Error(message);
          else {
            reset();
            navigate('/patient/phr', {replace: true});
          }
        } catch (error) {
          if(error.response) setServerError(error.response.data.message);
          else setServerError(error.message);
        }
        setSubmitting(false);
        [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
      }
    
    return (
        <div className="section">
        <section className="hero">
      <div className="">
    
        <div className="logcolumn">
        <form className="login-form"  onSubmit={handleSubmit(handlePHRUpdate)}  style={{height: 'max-content'}} name='phr-form'>
          <h2>Update Health Information</h2><br />
          {fields.map((row, index) =><div key={index}  className='form-row' > 
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors}
            
           
            placeholder={healthProfile ? healthProfile[fieldName] : '0'}   
            />
            )) }
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Update</button></p>
            <br /><br />
 
       </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>
        </div>
 
       
      </div>
    </section>

      
        <br/><br/><br/><br/><br/><br/>
      </div>
       

    );

}

export default PatientHealthUpdate;