

// import { NavLink } from "react-router-dom";
// import '../StaticHome/css/style.css';

// const NavigationBarComponent = ({links =[], heading}) => {
//   return (
//     <nav className="" style={{borderRadius: 0}}>
//         <div className="logo">Smart Health Hub</div>
//         {heading &&<div className="dashboard-heading">{heading}</div>}
//         <div className="nav-items">
//           {links.map(({to, label}, index) =>(<NavLink to={to} key={index}>{label}</NavLink>)) }
          
//         </div>
//       </nav>
//   )
// }

// export default NavigationBarComponent;

import { NavLink } from "react-router-dom";
import '../StaticHome/css/style.css';

const NavigationBarComponent = ({ links = [], heading }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light " style={{ borderRadius: 0 }}>
      <div className="container">
        <NavLink className="navbar-brand logo" to="/">Smart Health Hub</NavLink>
   
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
  <span className="navbar-toggler-icon"></span>
</button>

        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            {heading && <li className="nav-item"><span className="nav-link dashboard-heading">{heading}</span></li>}
            {links.map(({ to, label }, index) => (
              <li className="nav-item" key={index}>
                <NavLink className="nav-link" to={to}>{label}</NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBarComponent;
