import {NavLink} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import '../StaticHome/css/style.css';
import logImage from '../../assets/signpic.png';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation , useParams  } from 'react-router-dom';
import { fetch_userbyid,updateuser } from '../../api/admin';
const AdminUserUpdate = () => {
  const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
  const [serverError, setServerError] =useState(null);
  const [submitting, setSubmitting] =useState(false);
  const navigate =useNavigate();

   
    
  const { id } = useParams();

        
  const formatDate =timestamp =>{
      const new_date =new Date(timestamp);
      const format_2dgt =v =>parseInt(v) <10? `0${v}`: v;
      const days =['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      const year =new_date.getFullYear();
      const month =format_2dgt(new_date.getMonth()+1);
      const day =new_date.getDay()-1;
      const date =format_2dgt(new_date.getDate());
      return `${days[day]}, ${date}-${month}-${year}`;
  }


const [userData, setUserData] = useState(null);

useEffect(() => {
  
  const fetchUserData = async () => {
    try {
      console.log(id);
      const data = await fetch_userbyid(id);
      console.log(data);
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  if(!userData){
  fetchUserData();
  }
});


  const fields =[
    [{fieldName: 'FirstName', label: 'First name', validations: {
      required: "First name Required*"
    }},
    {fieldName: 'LastName', label: 'Last Name', validations: {
      required: "Last name Required*"
    }}],

    [
    {fieldName: 'Password', label: 'Reset Password', type: 'password', validations: {
      required: "Password Required*"
    }},
    {fieldName: 'cpassword', label: 'Reset Confirm Password', type: 'password', validations: {
      required: "Confirm Password*",
      validate: val => val !=watch('Password')? 'Passwords do not match': null,
    }}
   ],

   [ 
    // {fieldName: 'DateOfBirth', label: 'Date of Birth', validations: {
    //   required: "Provide your date of birth*"
    // }, type: 'date'},
    {fieldName: 'PhoneNumber', label: 'Phone', type: 'tel', placeholder: '123-45-678', validations: {
      required: "Phone number Required*",
      pattern: {
        value: /^[0-9-]+$/,
        message: 'Invalid phone number'
      }
    }}
],
 
    [
      {fieldName: 'Gender', label: 'Gender', type: 'select', validations: {
        required: "Gender Required*"
      }, options: [
        {value: '', label: 'Select', disabled: true, selected: true},
        {value: 'male', label: 'Male'},
        {value: 'female', label: 'Female'},
        {value: 'bi', label: 'Bi'},
        {value: 'private', label: 'Don\'t want to disclose'},
      ]},
      ],
  ]

  const handleUserUpdate =async values =>{
    setServerError(null);
    setSubmitting(true);
    const form =document.forms['upd-form'];
    try {
      [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
      delete values.cpassword;
      const {status, message} =await updateuser(values);

      if(status >=400) throw new Error(message);
      else {
        reset();
        navigate('/administrator', {replace: true});
      }
    } catch (error) {
      if(error.response) setServerError(error.response.data.message);
      else setServerError(error.message);
    }
    setSubmitting(false);
    [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
  }

  return (
      <div className="">
           {userData ? (
        <section className="">
      <div className="container common-container">
    <div className='row'>
        <div className="col-sm">
          <div className='loginform-block'>
        <form className="upd-form" onSubmit={handleSubmit(handleUserUpdate)} style={{height: 'max-content'}} name='upd-form'>
          <h2>Update User Information</h2><br />
          {fields.map((row, index) =><div key={index} className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} 
           placeholder= {userData[fieldName]}
           />))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Update</button></p>
          
 </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>
        </div>
        </div>
 
     
      </div>
      </div>
    </section>
 ) : (
  // Render loading indicator or message
  <p>Loading user data...</p>
)}
      
        <br/><br/><br/><br/><br/><br/>
      </div>
  )
}

export default AdminUserUpdate;