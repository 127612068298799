import {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import { FormInputComponent, LoadingAnimationComponent } from '../components';
import '../StaticHome/css/style.css';
import logImage from '../../assets/signpic.png';

const HealthAdministratorStaffAccess = () => {
  const {register, handleSubmit, reset, watch, formState: {errors}} =useForm();
  const [serverError, setServerError] =useState(null);
  const [submitting, setSubmitting] =useState(false);
  const navigate =useNavigate();

  const fields =[
 
 
    [
      {fieldName: 'gender', label: 'Read Access', type: 'select', validations: {
        required: "Read Required*"
      }, options: [
        {value: '', label: 'Select', disabled: true, selected: true},
        {value: 'male', label: 'Yes'},
        {value: 'female', label: 'No'},
      ]},
      {fieldName: 'gender', label: 'Write Access', type: 'select', validations: {
        required: "Read Required*"
      }, options: [
        {value: '', label: 'Select', disabled: true, selected: true},
        {value: 'male', label: 'Yes'},
        {value: 'female', label: 'No'},
      ]},
      {fieldName: 'gender', label: 'Update Access', type: 'select', validations: {
        required: "Read Required*"
      }, options: [
        {value: '', label: 'Select', disabled: true, selected: true},
        {value: 'male', label: 'Yes'},
        {value: 'female', label: 'No'},
      ]},
      {fieldName: 'gender', label: 'Delete Access', type: 'select', validations: {
        required: "Read Required*"
      }, options: [
        {value: '', label: 'Select', disabled: true, selected: true},
        {value: 'male', label: 'Yes'},
        {value: 'female', label: 'No'},
      ]},
      ],
  ]

  const handleSignup =async values =>{
    setServerError(null);
    setSubmitting(true);
    const form =document.forms['signup-form'];
    try {
      [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =true);
      delete values.cpassword;
      const {status, message} =await create_user(values);

      if(status >=400) throw new Error(message);
      else {
        reset();
        navigate('/login', {replace: true});
      }
    } catch (error) {
      if(error.response) setServerError(error.response.data.message);
      else setServerError(error.message);
    }
    setSubmitting(false);
    [...form.querySelectorAll('input, select, button')].forEach(field => field.disabled =false);
  }

  return (
      <div className="">
        <section className="">
      <div className="container common-container">
    <div className='row'>
        <div className="col-sm">
          <div className='loginform-block'>
        <form className="login-form" onSubmit={handleSubmit(handleSignup)} style={{height: 'max-content'}} name='login-form'>
          <h2>Update Staff Access</h2><br />
          {fields.map((row, index) =><div key={index} className='form-row'>
            {row.map(({fieldName, type, label, options, validations, placeholder}, index) =>(<FormInputComponent name={fieldName} label={label} key={index} type={type} options ={options} register={register(fieldName, validations)} error={errors} placeholder={placeholder}/>))}
          </div>)}
          <div className="options-end">
            <br />
            {submitting? <LoadingAnimationComponent />: (<>
           <p className='newAcc'> <button className="health-btn" type="submit">Update</button></p>
          
 </>)}
          {serverError &&<div className="error-alert">{serverError}</div>}
         
          </div>
        </form>
        </div>
        </div>
 
     
      </div>
      </div>
    </section>

      
        <br/><br/><br/><br/><br/><br/>
      </div>
  )
}

export default HealthAdministratorStaffAccess;