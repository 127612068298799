import React from "react";
import "./servicesstyle.css";

const About = () => {
  return (
    <div class="container">
   <div class="jumbotron text-center">
  <h1 class="display-4">About Smart Health Hub</h1>
  <p class="lead">Your trusted partner in healthcare</p>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-6">
      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">Our Mission</h2>
          <p class="card-text">We aim to provide accessible and affordable healthcare solutions using the latest technology and a team of dedicated professionals. Our mission is to empower individuals to take control of their health and well-being.</p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">Our Vision</h2>
          <p class="card-text">Our vision is to revolutionize the healthcare industry by creating a seamless and integrated platform that connects patients, doctors, and healthcare providers. We envision a future where healthcare is personalized, proactive, and preventive.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">Why Choose Us?</h2>
          <p class="card-text">We offer a wide range of healthcare services, from primary care to specialized treatments, all under one roof. Additionally, we leverage cutting-edge technology to deliver efficient and effective healthcare solutions.</p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">Our Team</h2>
          <p class="card-text">We have a team of experienced and compassionate healthcare professionals dedicated to providing the highest quality care to our patients.</p>
        </div>
      </div>
    </div>
  </div>
</div>

  </div>
  
  );
};
export default About;