import { NavLink } from 'react-router-dom';
import HeroImage from '../../assets/illustration1.png';
import '../commonstyle.css';
import './assets/style.css';
import heartImg from '../../assets/heart.png';
import sugarImg from '../../assets/sugar.png';
import appointmentImg from '../../assets/appointmentillustration.png';
import { checkSymptoms, fetchAppointsbyid, fetchAllAppointsbyid, cancelAppbyid} from '../../api/common';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const PatientDashboard = () => {
    const navigate = useNavigate();
    const [symptoms, setSymptoms] = useState([]);
    const storedSessionDataString = localStorage.getItem('session');
    const storedSessionData = JSON.parse(storedSessionDataString);
    const currentUser = storedSessionData.id;
    console.log(currentUser);
    const [appoints, setAppoints] = useState([]);
    const [allAppointments, setAllAppointments] = useState([]);
    
    const handleSymptomChange = (e) => {
      const { value } = e.target;
      console.log(symptoms);
      setSymptoms(value);//.split(',').map(symptom => symptom.trim())
    };
  
   
  
    const addhandleClick = () => {
        navigate('/patient/phr/appointmentadd', {replace: true});

    };

    useEffect(() => {
        const fetchAppoints = async() => {
            try {
                const  {status, data}  = await fetchAppointsbyid(currentUser);
                // Handle fetched posts
                if(status >=400) throw new Error(message);
          else {
                console.log('map:',data);
             setAppoints(data);
          }
            } catch (error) {
                console.error('Error fetching Appoints:', error);
                // Handle error
            }
        };
    
        fetchAppoints();
    }, []);

    useEffect(() => {
        const fetchAllAppoints = async() => {
            try {
                const  {status, data}  = await fetchAllAppointsbyid(currentUser);
                // Handle fetched posts
                if(status >=400) throw new Error(message);
          else {
                console.log('map:',data);
                setAllAppointments(data);
          }
            } catch (error) {
                console.error('Error fetching Appoints:', error);
                // Handle error
            }
        };
    
        fetchAllAppoints();
    }, []);

    const checkSymp = async (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
    
        const form = document.forms['post-form'];
        try {

            if (!symptoms || symptoms.length === 0) {
                console.log('Symptoms not entered');
                return; // Exit the function if symptoms are not entered
            }
        
            console.log(symptoms);
            const { status, data } = await checkSymptoms(symptoms);
            console.log('here',data);
         
               
                const sympResultElement = document.querySelector('.sympResult');
        if (sympResultElement) {
            sympResultElement.textContent =    `${data.SymptomResult}`;
        }
        
        } catch (error) {
           // console.log(error);
        }
    }
    

    return (
        <div className="healthcare-dashboard">
            <div className="desktop-wrapper">
                
                <div className="desktop">
                <div className='row'>
                        <div className='col-sm'>
                            <div className='appointment-block'>
                            <h2>Appointments</h2><br />
                            {appoints.map((app) => (
    <div className="dash-appointment" key={app.AppointmentID}>
        <div className="view">
            <div className='row'>
                <div className='col-sm-5'>
                    <div className="title"><h5>Upcoming Appointment</h5></div>
                </div>
                <div className='col-sm-2'>
                    <div className="frame-box">
                        <div className="text-wrapper-54">{app.DateTime}</div>
                    </div>
                </div>
                <div className='col-sm-5'>
                    <div className="doctorname"><p>{app.StatusName}</p></div>
                </div>
            </div>                                
        </div>
        <br/>
    </div>
    
))}

                        <br/>
                       
                        <br/>
                        <div className='appointment-btns'>
                        <div className='row'>
                        <div className='col-md-4'>
                                    <img className="" alt="Group" src={appointmentImg} />
                                    </div>
    <div className='col-md-4'>
    </div>
    <div className='col-md-4'>
    <button className="health-btn" onClick={addhandleClick} type="submit">Schedule Appointment</button>
<br/>
<br/>
    </div>


 
    </div>
    <div className='row'>
    <div className="container">
      <h2>All Appointments</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>AppointmentId</th>
            <th>Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allAppointments.map((appointment) => (
            <tr key={appointment.AppointmentID}>
              <td>{appointment.AppointmentID}</td>
              <td>{appointment.DateTime}</td>
              <td>{appointment.StatusName}</td>
              <td>

                {appointment.StatusID === 1 && (

                  <p><NavLink to={`phr/appointmentupdate/${appointment.AppointmentID}`}>Manage</NavLink></p>
                  //   <button
                //   className="health-btn"
                //   onClick={() => updatehandleClick(appointment.AppointmentID)}
                // >
                //   Cancel
                // </button>
                
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  </div>
  </div>

                        </div>
                        <div className='col-sm'>
                            <div className='row'>
                           
                            <div className="symptom-checker">
                                            <br/>
                                            <h2>Symptom Checker</h2>
                                            <form onSubmit={checkSymp}>
                                                <label htmlFor="symptoms">Enter your symptoms (comma-separated):</label>
                                                <input
                                                    type="text"
                                                    id="symptoms"
                                                    name="symptoms"
                                                  
                                                    onChange={handleSymptomChange}
                                                    placeholder="e.g., headache, fever, cough"
                                                />
                                                <br/>
                                                <button className="health-btn" type="submit">Assess Symptoms</button>
                                               <br/>
                                                <h4 >Result:</h4>
                                                <br/><p className='sympResult'></p>
                                                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                           </form>
                                        </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>

    );

}

export default PatientDashboard;