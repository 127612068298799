import React from "react";
import "./servicesstyle.css";

const ServicesPage = () => {
  return (
    <div class="container">
    <h2 class="mt-4 mb-4">Our Services</h2>
    <div class="row">
    
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Search Doctor</h5>
            <p class="card-text">Choose your doctor from thousands of specialists, general, and trusted hospitals.</p>
          </div>
        </div>
      </div>
      
      
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Consultation</h5>
            <p class="card-text">Free consultation with our trusted doctors and get the best recommendations.</p>
          </div>
        </div>
      </div>
      
  
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Online Pharmacy</h5>
            <p class="card-text">Buy your medicines with our mobile application with a simple delivery system.</p>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row">
   
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Details Info</h5>
            <p class="card-text">Free consultation with our trusted doctors and get the best recommendations.</p>
          </div>
        </div>
      </div>
      
  
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Tracking</h5>
            <p class="card-text">Track and save your medical history and health data.</p>
          </div>
        </div>
      </div>
  
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Emergency Care</h5>
            <p class="card-text">You can get 24/7 urgent care for yourself, your children, and your lovely family.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
};
export default ServicesPage;